import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../functions/ScrollToTop";
import TemplateCard from "../design/TemplateCard";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";

const text = hyphenated(
  `<p>
  Unfälle, Vorfälle, Risiken für Vorfälle oder Fehlfunktion im Betrieb, welche schädigende Konsequenzen für die Bewohnerinnen, Patientinnen, 
  Besucherinnen, Begleitpersonen oder für das Eigentum des Betriebs bzw. des Unternehmens haben könnten, 
  sind als unerwünschte Ereignisse zu bewerten und entsprechend zu bearbeiten. 
  Mit Hilfe eines Formulars können Mitarbeitende, Bewohner*innen, Angehörige oder andere Personen anonym oder mit 
  Nennung des Namens das unerwünschte Ereignis melden. Es ist ein Prozess zu definieren, 
  wie das Pflegeheim mit diesem Ereignis umgeht. Es gibt die folgenden Kategorien (nicht abschliessend) für unerwünschte Ereignisse:

  <br><br>
    <ul>
  <li>Unfall, Verletzung, Therapiezwischenfall</li>
  <li>Entweichen, Abgängigkeit</li>
  <li>Verleumdung</li>
  <li>Epidemie, Infektionsausbreitung, Vergiftung</li>
  <li>Gewalttätigkeit</li>
  <li>Arbeitsunfälle</li>
  <li>Katastrophen (Unwetter, Überschwemmung, Feuer)</li>
  <li>Einbruch, Diebstahl</li>
  <li>Suizid, versuchter Suizid</li>
  <li>Sexuelle Belästigung</li>
  <li>Data Breach</li>
  <li>Fehlmedikation</li>
  <li>Sonstiges</li>
  </ul>
  </p>`,
  { language: de }
);

class Beschwerdesystem extends Component {
  static name = "Beschwerdesystem";
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Identifikation">
              &lt; Zurück zur Risikoidentifikation
            </Link>
            <br></br>
            <br></br>
            <h1>Beschwerdesystem</h1>
            <div dangerouslySetInnerHTML={{ __html: text }}></div>
            <TemplateCard
              style="prozess"
              title="Formular unerwünschte Ereignisse"
              description="Ein Muster-Meldeformular für unerwünschte Ereignisse"
              format="Word"
              image="Beschwerdeformular"
              download="Muster_Formular_Unerwuenschte_Ereignisse.docx"
            />
          </div>
      </>
    );
  }
}

export default Beschwerdesystem;
