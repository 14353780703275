import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../functions/ScrollToTop";
import TemplateCard from "../design/TemplateCard";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";

const text = hyphenated(
  `<p>

  Die Prozesskettenanalyse identifiziert Risiken in Geschäftsprozessen durch Simulation und Mitarbeitendenbeteiligung. Sie basiert auf vorhandenen Prozesslandkarten und Organisationsaufzeichnungen. Die Darstellung kann tabellarisch oder grafisch sein, um eine klare Übersicht zu bieten und Risikoquellen zu finden.
  <br></br>
  
  </p>`,
  { language: de }
);

class Prozesskettenanalyse extends Component {
  static name = "Prozesskettenanalyse";
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Identifikation">
            &lt; Zurück zur Risikoidentifikation
          </Link>
          <br></br>
          <br></br>
          <h1>Prozesskettenanalyse</h1>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>

          <TemplateCard
            style="prozess"
            title="Prozesskette"
            description="Vorlage für die Analyse der Prozesskette."
            format="PowerPoint"
            image="Prozesskette"
            download="Template_Prozesskette_mit_Beispiel.pptx"
          />
        </div>
      </>
    );
  }
}

export default Prozesskettenanalyse;
