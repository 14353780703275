import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../functions/ScrollToTop";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";

const text = hyphenated(
  `<p>
  Bei der Risikoanalyse sind Wechselwirkungen zwischen Risiken wichtig. Es gibt vier Arten: Risikoantinomie, bei der ein Risiko ein anderes ausschliesst; Risikokonkurrenz, bei der ein Risiko das Eintreten eines anderen verringert und negative Korrelationen genutzt werden können; Risikokomplementarität, bei der zwei Risiken positiv korrelieren und unvorhergesehene Kombinationen negative Folgen haben können; und Risikoindifferenz, bei der Risiken unabhängig voneinander auftreten können. Dies hilft, Risiken besser zu verstehen und Strategien zur Risikominimierung zu entwickeln. 
  </p>`,
  { language: de }
);

class Wechselwirkungen extends Component {
  static name = "Wechselwirkungen";
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Analyse">
            &lt; Zurück zur Risikoanalyse und -bewertung
          </Link>
          <br></br>
          <br></br>
          <h1>Wechselwirkungen zwischen Risiken</h1>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </>
    );
  }
}

export default Wechselwirkungen;
