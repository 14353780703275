import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../functions/ScrollToTop";

import de from "hyphenated-de";
import { hyphenated } from "hyphenated";

const text = hyphenated(
  `<p>
 
  Brainstorming ist eine weit verbreitete Kreativitätstechnik zur Ideenfindung und Risikobewältigung. Ursprünglich aus der Werbung stammend, wird es in verschiedenen Bereichen eingesetzt. Teilnehmende generieren ohne Einschränkungen Ideen zu Risiken oder Lösungen. Dann werden diese sortiert und bewertet. Im Risikomanagement fördert es eine offene Kultur und unterstützt Szenario-Analysen.
  <br><br>
  
  </p>`,
  { language: de }
);

class Brainstorming extends Component {
  static name = "Brainstorming";
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Identifikation">
            &lt; Zurück zur Risikoidentifikation
          </Link>
          <br></br>
          <br></br>
          <h1>Brainstorming</h1>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </>
    );
  }
}

export default Brainstorming;
