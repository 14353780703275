import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../functions/ScrollToTop";
import TemplateCard from "../design/TemplateCard";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";

const text = hyphenated(
  `<p>
  Die Fehlerbaumanalyse beginnt mit einem unerwünschten Ereignis auf oberster Ebene und untersucht, wie Einzelfunktionen auf unteren Ebenen dazu beitragen könnten. So entsteht eine Fehlermöglichkeitsbaum-Struktur, die durch logische Verknüpfungen (UND, ODER) Kombinationen für das unerwünschte Ereignis identifiziert. Sie dient der Risikoidentifikation und -bewertung.
  <br></br>
  
  </p>`,
  { language: de }
);

class Fehlerbaumanalyse extends Component {
  static name = "Fehlerbaumanalyse";
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Identifikation">
            &lt; Zurück zur Risikoidentifikation
          </Link>
          <br></br>
          <br></br>
          <h1>Fehlerbaumanalyse</h1>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
          <TemplateCard
              style="prozess"
              title="Fehlerbaum"
              description="Vorlage für die Risikoanalyse mittels Fehlerbaum."
              format="PowerPoint"
              image="Fehlerbaum"
              download="Template_Fehlerbaum.pptx"
            />
        </div>
      </>
    );
  }
}

export default Fehlerbaumanalyse;
