import React, { useState, useMemo, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { faCheckSquare, faCoffee } from "@fortawesome/fontawesome-free-solid";
import Search from "./functions/Search";
import { GrundlagenRisikomanagement } from "./grundlagen/GrundlagenRisikomanagement";
import Schnittstellen from "./grundlagen/Schnittstellen";
import Rechtliches from "./grundlagen/Rechtliches";
import Identifikation from "./identifikation/Identifikation";
import Risikokatalog from "./identifikation/Risikokatalog";
import Beschwerdesystem from "./identifikation/Beschwerdesystem";
import Brainstorming from "./identifikation/Brainstorming";
import Dokumentenanalyse from "./identifikation/Dokumentenanalyse";
import Fehlerbaumanalyse from "./identifikation/Fehlerbaumanalyse";
import Prozesskettenanalyse from "./identifikation/Prozesskettenanalyse";
import Wertkettenanalyse from "./identifikation/Wertkettenanalyse";
import WorldCafe from "./identifikation/WorldCafe";
import Überwachung from "./überwachung/Überwachung";
import Bewältigung from "./bewältigung/Bewältigung";
import Analyse from "./Analyse/Analyse";
import Fehlermeldesysteme from "./Analyse/Fehlermeldesysteme";
import Quantifizierung from "./Analyse/Quantifizierung";
import Risikomatrix from "./Analyse/Risikomatrix";
import Szenariotechnik from "./Analyse/Szenariotechnik";
import Wechselwirkungen from "./Analyse/Wechselwirkungen";
import Bibliothek from "./Bibliothek";
import Glossar from "./Glossar";
import Hilfe from "./Hilfe";
import Info from "./Info";


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1.3rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#222e37",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: "4px",
    Typography: {
      fontSize: "1.0rem",
    },
  },
}));

const Navbar = () => {
  const searchRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const filteredModules = useMemo(() => {
    if (!searchQuery) {
      return [GrundlagenRisikomanagement, Rechtliches, Schnittstellen, Identifikation, Risikokatalog, Beschwerdesystem, Brainstorming, Dokumentenanalyse, Fehlerbaumanalyse, Prozesskettenanalyse, Wertkettenanalyse, WorldCafe, Überwachung, Analyse, Fehlermeldesysteme, Quantifizierung, Risikomatrix, Szenariotechnik, Wechselwirkungen];
      
    }

    const filtered = [
      GrundlagenRisikomanagement,
      Rechtliches,
      Schnittstellen,
      Identifikation,
      Risikokatalog,
      Brainstorming, 
      WorldCafe, 
      Wertkettenanalyse,
      Prozesskettenanalyse, 
      Dokumentenanalyse,
      Fehlerbaumanalyse,
      Beschwerdesystem,
      Überwachung,
      Analyse, 
      Fehlermeldesysteme,
      Quantifizierung,
      Risikomatrix,
      Szenariotechnik,
      Wechselwirkungen,
      Bibliothek,
      Glossar,
      Hilfe,
      Info
    ];

    return filtered;
  }, [searchQuery]);

  const [clicked, setClicked] = useState(false);
  const [search, setSearch] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
    setSearch(false);
  };

  var searchCounter;

  const handleSearchActive = () => {
    setSearch(true);
  };

  const handleSearch = () => {
    setSearchQuery("");
    setSearch(false);
  };

  return (
    <>
      <nav>
        <div id="logo" onClick={() => handleSearch()}>
          <Link to="/">
            <img id="logo" src="/logos/logo_funk_stiftung.jpg" alt="logo"></img>
          </Link>
        </div>
        <div id="searchbar">
          <input
            ref={searchRef}
            type="text"
            placeholder="Suche"
            value={searchQuery}
            onChange={(e) => {setSearchQuery(e.target.value); handleSearchActive()}}
          />
          {filteredModules.length > 0 && (
            <div
              className="search-results"
              style={{
                visibility: search ? "visible" : "hidden",
              }}
            >
              <Search
                modules={filteredModules}
                searchQuery={searchQuery}
                handleClick={handleSearch}
              />
            </div>
          )}
        </div>
        <div>
          <ul id="navbar" className={clicked ? "#navbar active" : "#navbar"}>
            <li>
              <Link to="/" onClick={handleClick}>
                Home
              </Link>
            </li>
            <li className="accordion">
              <Accordion
                sx={{
                  backgroundColor: "transparent",
                }}
              >
                <AccordionSummary>
                  <Typography
                    sx={{
                      fontSize: "1.3rem",
                      fontWeight: "600",
                      textDecoration: "none",
                      backgroundColor: "none",
                      fontFamily:
                        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
                    }}
                  >
                    Grundlagen
                  </Typography>
                </AccordionSummary>
                <ul id="subnav">
                  <li>
                    <Link to="GrundlagenRisikomanagement" onClick={handleClick}>
                      Allgemein
                    </Link>
                  </li>
                  <li>
                    <Link to="Schnittstellen" onClick={handleClick}>
                      Schnittstellen
                    </Link>
                  </li>
                  <li>
                    <Link to="Rechtliches" onClick={handleClick}>
                      Rechtliches
                    </Link>
                  </li>
                </ul>
              </Accordion>
            </li>
            <li>
              <Link to="Prozess" onClick={handleClick}>
                Integriertes Risikomanagement
              </Link>
            </li>
            <li>
              <Link to="Bibliothek" onClick={handleClick}>
                Bibliothek
              </Link>
            </li>
            <li>
              <Link to="Glossar" onClick={handleClick}>
                Glossar
              </Link>
            </li>
            <ul id="infoAndHelp">
              <li>
                <Link to="Info" onClick={handleClick}>
                  <FontAwesomeIcon icon="fa-solid fa-info" />
                </Link>
              </li>
              <li>
                <Link to="Hilfe" onClick={handleClick}>
                  <FontAwesomeIcon icon="fa-solid fa-question" />
                </Link>
              </li>
            </ul>
          </ul>
        </div>

        <div id="mobile" onClick={handleClick}>
          <i id="bar" className={clicked ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
      </nav>
      <div className="navbar_style_inwards"></div>
      <div className="navbar_style_outwards"></div>

      <div
        className={clicked ? "overlay active" : "overlay"}
        onClick={handleClick}
      ></div>
    </>
  );
};

export default Navbar;
