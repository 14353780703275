import React from "react";
import { Component } from "react";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";


const text = hyphenated(
 ` <h2> Impressum</h2></br>
OST Ostschweizer Fachhochschule<br>
Institut für Finance und Law<br>
Prof. Dr. Wilfried Lux<br>
Rosenbergstrasse 59<br>
CH-9001 St.Gallen
<br></br>
 
 <h2>Allgemeine Geschäftsbedingungen</h2></br>
 Die Inhalte dieser Webapp stammen von der Ostschweizer Fachhochschule (Prof. Dr. Wilfried Lux, Prof. Dr. Heidi Zeller und Sebastian Müller) 
 und wurden in enger Zusammenarbeit mit Funk und diversen Praxisvertretern erstellt und weiterentwickelt. 
 Die Funk Stiftung ist formeller Anbieter der Webapp.  
  <br></br>
Sämtliche Inhalte der Webapp werden Ihnen kostenlos zur Verfügung gestellt und wurden nach bestem Wissen und Gewissen geprüft. 
  <br></br>
Für die inhaltliche Korrektheit und die technische Verfügbarkeit wird keine Haftung übernommen. 
Wir freuen uns über Anregungen und Vorschläge zur Verbesserung der Webapp und der zur Verfügung gestellten Inhalte und Templates: 
<a href="mailto:wilfried.lux@ost.ch">wilfried.lux@ost.ch</a>
<br></br>
<h2>Datenschutzbestimmungen</h2><br>
<h3>Grundlegendes</h3>
Diese Datenschutzerklärung bezieht sich allein auf die Webapp für ganzheitliches Risikomanagement (im Folgenden nur noch als „Webapp“ bezeichnet) und soll die Nutzer über die Art, den Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten durch den Betreiber informieren.<br>
Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst und legen daher Wert darauf, dass Sie wissen, wann wir welche Daten erheben und wie wir sie verwenden. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Vorschriften.<br>
Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”) finden Sie in Art. 4 DSGVO.
Da durch neue Technologien und die Weiterentwicklung dieser Webapp Änderungen an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen. Wenn Sie Fragen zum Datenschutz haben, nehmen Sie bitte Kontakt mit uns auf.
<br><br>
<h3>Verantwortlich für die Datenverarbeitung</h3>

Soweit über die Webapp personenbezogene Daten verarbeitet werden, ist Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DSGVO):
<br><br>
OST Ostschweizer Fachhochschule<br>
Institut für Finance und Law<br>
Rosenbergstrasse 59<br>
9001 St.Gallen<br><br>


Telefon: +41 58 257 13 90<br>
E-Mail: ifl@ost.ch<br>
Internet: www.ost.ch/ifl<br>
<br>
<h3>Datenerfassung, Speicherdauer und Datenlöschung</h3>

Die Webapp bietet keine Funktionen für welche Daten gesammelt und gespeichert werden. 
Sämtliche Templates welche zum Download zur Verfügung gestellt werden, werden danach lokal auf Ihrem Gerät gespeichert und bearbeitet und die Informationen weder an den Webapp-Betreiber noch an eine andere Partei gesendet. 
Der Versand von Download-Links via Mail erfolgen über Dienste von Drittanbietern, der Webapp-Betreiber erhält keine Information darüber, an welche Kontakt die Downloadlinks versendet werden. 
<br><br>
<h3>Datensicherheit</h3>
Da die zur Verfügung gestellten Templates ausschließlich auf Ihrem Gerät gespeichert werden (vgl. vorherigen Abschnitt), kann der Webapp-Betreiber für etwaige Manipulationen, Verlust, Zerstörung oder lokalen Zugriff durch unberechtigte Personen nicht haftbar gemacht werden.<br>
<br>
<h3>Haftung für Links</h3>

Unser Angebot enthält Links zu externen Websites Dritter (insbesondere aus der "Bibliothek"), auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
<br><br>
<h3>Urheberrecht</h3>
Die durch den Webapp-Betreiber erstellten Inhalte und Werke in dieser Webapp unterliegen dem Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Vervielfältigungen und Kopien dieser Inhalte sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
<br><br>
<h3>Kontaktaufnahme per E-Mail</h3>
Bei Ihrer Kontaktaufnahme mit uns per E-Mail werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, Ihr Name und Ihre Telefonnummer) von uns gespeichert, um Ihre Fragen zu beantworten. Eine Mitteilung dieser Angaben erfolgt ausdrücklich auf freiwilliger Basis und mit Ihrer Einwilligung, Art. 6 Abs.1 a DSGVO. Soweit es sich hierbei um Angaben zu Kommunikationskanälen (beispielsweise E-Mail-Adresse, Telefonnummer) handelt, willigen Sie außerdem ein, dass wir Sie ggf. auch über diesen Kommunikationskanal kontaktieren, um Ihr Anliegen zu beantworten. Diese Einwilligung können Sie selbstverständlich jederzeit mit Wirkung für die Zukunft widerrufen.
Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen.
<br><br>
<h3>Erfassung und Verarbeitung personenbezogener Daten</h3>

Wir erheben, nutzen oder geben Ihre personenbezogenen Daten nur dann weiter, wenn dies im gesetzlichen Rahmen erlaubt ist oder Sie in die Datenerhebung einwilligen.<br>
Als personenbezogene Daten gelten sämtliche Informationen, welche dazu dienen, Ihre Person zu bestimmen und welche zu Ihnen zurückverfolgt werden können – also beispielsweise Ihr Name, Ihre E-Mail-Adresse und Telefonnummer.<br>
Personenbezogene Daten werden nur dann erhoben und gespeichert, wenn Sie uns ausdrücklich und wissentlich solche Informationen für bestimmte Zwecke zur Verfügung stellen, zum Beispiel bei Anfragen. Die von Ihnen eingegebenen personenbezogenen Daten verwenden wir nur zu dem von Ihnen gewünschten Zweck und aufgrund unseres berechtigten Interesses zur Erfüllung unserer vertraglich vereinbarten Leistungen und löschen sie danach wieder.

Daten, die beim Zugriff auf unser Internetangebot gespeichert worden sind, werden an Dritte nur übermittelt,<br>
<br>
<ul>
<li>wenn dies im Rahmen unserer Leistungserbringung erforderlich ist (z.B. Briefversand) oder</li>
<li>wenn wir hierzu gesetzlich  oder durch richterliche Anordnungen verpflichtet sind oder</li>
<li>falls die Weitergabe im Falle von Angriffen auf unsere  Internetinfrastruktur zur Rechts- oder Strafverfolgung erforderlich ist.</li>
</ul>
<br>
Eine Weitergabe zu anderen nichtkommerziellen oder zu kommerziellen Zwecken erfolgt nicht.
<br><br>
<h3>Datenübertragung</h3>

E- Mail-Inhalte unterliegen dem Fernmeldegeheimnis, dessen Beachtung wir für unseren eigenen Einflussbereich sicherstellen. Die Datenübertragung über das Internet kann aber prinzipiell von anderen Internet-Betreibern und Nutzern aufgezeichnet werden. Darauf haben wir keinen Einfluss. Personenbezogene Daten werden deshalb nach Möglichkeit nur verschlüsselt übertragen.
<br><br>
<h3>Rechte des Nutzers</h3>
Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten, welche personenbezogenen Daten über Sie gespeichert wurden. Sie haben außerdem das Recht auf Berichtigung falscher Daten und auf die Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen Daten. Falls zutreffend, können Sie auch Ihr Recht auf Datenportabilität geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet wurden, können Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen.
<br><br>
<h3>Löschung von Daten</h3>
Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung) kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung nicht mehr vonnöten sein und es keine gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls eine Löschung nicht durchgeführt werden kann, da die Daten für zulässige gesetzliche Zwecke erforderlich sind, erfolgt eine Einschränkung der Datenverarbeitung. In diesem Fall werden die Daten gesperrt und nicht für andere Zwecke verarbeitet.
<br><br>
<h3>Widerspruchsrecht</h3>

Nutzer dieser Webapp können von ihrem Widerspruchsrecht Gebrauch machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder Zeit widersprechen.
Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl. der Erhebung, Verarbeitung oder Verwendung Ihrer personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen möchten, wenden Sie sich bitte an folgende E-Mail-Adresse: ifl(at)ost.ch
<br><br>
`,



{ language: de }
)

class Info extends Component {
  static name = "Info";
  render() {
    return (
      <>
        <div class="App">
          <div class="root">
          <h1>Informationen zur Risikomanagement App</h1><br />
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
          </div>
        </div>
      </>
    );
  }
}

export default Info;
