import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../functions/ScrollToTop";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";

const text = hyphenated(
  `<p>
  Das World Café ist eine Methode für grosse Gruppen, bei der Teilnehmende an Tischen sitzen, ihre Ideen visualisieren und Fragen diskutieren. Moderatorinnen und Moderatoren leiten die Gespräche, und Gruppen wechseln zwischen den Tischen. Dieser Ansatz eignet sich für grosse Diskussionen und hilft nicht nur bei der Risikoidentifikation, sondern auch bei der Risikokommunikation.
  <br><br>
  </p>`,
  { language: de }
);

class WorldCafe extends Component {
  static name = "World Cafe"
  render() {
    return (
      <>
      <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Identifikation">
            &lt; Zurück zur Risikoidentifikation
          </Link>
          <br></br>
          <br></br>
          <h1>World Café</h1>
          <div 
          dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </>
    );
  }
}

export default WorldCafe;
