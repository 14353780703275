import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import Card from "../design/Card";
import ScrollToTop from "../functions/ScrollToTop";
import Button from "@mui/material/Button";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";

/**
 *  Overview for "Identifikation".
 *  One Card for every Subsite.
 *  Subsites need to be linked via index. js => <Route path="Subsite" element={<Subsite />} />
 */

const text = hyphenated(
  `<p>
  Die Identifikation von Risiken erfordert die Erkennung möglicher Gefahren, Ereignisse und Trends, die die Organisationsziele beeinträchtigen könnten. Dies ist ein entscheidender Schritt im Risikomanagementprozess, da Risiken oft übersehen werden. Das Ziel besteht darin, alle potenziellen Risiken, die die Unternehmensziele und -strategien gefährden könnten, systematisch zu erfassen.
  Es gibt zwei Ansätze zur Risikoidentifikation: progressiv und retrograd. Im progressiven Ansatz werden zunächst risikobehaftete Aktivitäten in allen Unternehmensbereichen ermittelt und später auf potenzielle Risiken untersucht. Im retrograden Ansatz werden zunächst Unternehmensziele und -strategien identifiziert; dann werden die Risiken ermittelt, die sich auf diese Ziele auswirken können.
  Nach der Identifikation werden die Risiken in einem Inventar aufgeführt und präzise beschrieben, einschliesslich des Kontexts, der Bedingungen und der Folgen. Verschiedene bewährte Methoden zur Risikoidentifikation stehen zur Verfügung
  
  <br><br>

  Die folgenden Methoden der Risikoidentifikation haben sich bewährt:
</p>`,
  { language: de }
);

class Identifikation extends Component {
  static name = "Identifikation";
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Prozess">
            &lt; Zurück zum Prozess
          </Link>
          <br />
          <br />
          <h1>Identifikation</h1>
          <br></br>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
          <br></br>
          <Card
            title="Risikokatalog"
            intro="Risiken werden in Risikokategorien und -felder eingeteilt"
            link="/Risikokatalog"
            icon="riskmap"
            active=""
          ></Card>

          <br></br>

          <Card
            title="Brainstorming"
            intro="Produzieren und kombinieren von Ideen zu einem bestimmten Risiko"
            link="/Brainstorming"
            icon="lightbulb"
            active=""
          ></Card>

          <br></br>

          <Card
            title="World Café"
            link="/WorldCafe"
            active=""
            icon="worldcafe"
            intro="Brainstorming für grosse Gruppen, aufgeteilt auf verschiedene Tische"
          ></Card>

          <br></br>

          <Card
            title="Wertkettenanalyse"
            link="/Wertkettenanalyse"
            active=""
            icon="wertkette"
            intro="Unterteilung von Tätigkeiten um risikobehaftete Bereiche zu identifizieren"
          ></Card>

          <br></br>

          <Card
            title="Prozesskettenanalyse"
            link="/Prozesskettenanalyse"
            active=""
            icon="prozess"
            intro="Lokalisierung von Risikoquellen durch einen Überblick der Prozesse"
          ></Card>

          <br></br>

          <Card
            title="Dokumentenanalyse"
            link="/Dokumentenanalyse"
            icon="dokumente"
            active=""
            intro="Auswertung von Unternehmensunterlagen bezüglich risikorelevanter Informationen"
          ></Card>

          <br></br>

          <Card
            title="Fehlerbaumanalyse"
            active=""
            icon="fehlerbaum"
            link="/Fehlerbaumanalyse"
            intro="Untersuchen der Auswirkung von unerwünschten Ereignissen"
          ></Card>

          <br></br>

          <Card
            title="Beschwerdesystem"
            active=""
            icon="formular"
            link="/Beschwerdesystem"
            intro="Meldung unerwünschter Ereignisse"
          ></Card>
        </div>
      </>
    );
  }
}

export default Identifikation;
