import React from "react";
import { Component } from "react";
import ScrollToTop from "./functions/ScrollToTop";
import Book from "./design/Book";

class Bibliothek extends Component {
  static name = "Bibliothek";
  render() {
    return (
      <>
        <ScrollToTop />
        <div className="App">
          <div className="content">
            <h1>Bibliothek</h1>
            <br />
            <Book
              number="1"
              citation="Ahrens, J. (2020). Klinische Behandlungspfade als Instrument zur Unterstützung des Qualitäts- und Risikomanagements; in: Zapp, W. (Hrsg.) (2020), Qualitäts- und Risikomanagement im Krankenhaus – Analyse, Verfahren, Anwendungsbeispiele. Berlin: Springer-Verlag, S. 1-58."
              linktext="Zum Verlag "
              url="https://link.springer.com/book/10.1007/978-3-658-31491-0"
            ></Book>
            <Book
              number="1"
              citation="Andrews, K.R. (1971). The Concept of Corporate Strategy; in: The Concept of Corporate Strategy, Homewood, Il., Dow Jones-Irwin, S. 18-46"
              linktext="Zu Amazon "
              url="https://www.amazon.com/Concept-Corporate-Strategy-Kenneth-Andrews/dp/0256023719"
            ></Book>
            <Book
              number="1"
              citation="Ansoff, H.I. (1965): Corporate Strategy – An analytic approach to business policy for growth and expansion. New York: McGraw Hill"
              linktext="Zu Amazon "
              url="https://www.amazon.com/Concept-Corporate-Strategy-Kenneth-Andrews/dp/0256023719"
            ></Book>
            <Book
              number="1"
              citation="Baars, S. (2014): Risikomanagement und Anforderungen an Arbeitssicherheit und Gesundheitsschutz; in: Blonski, H. (Hrsg.) (2014), Risikomanagement in der stationären Altenpflege: Anforderungen, Methoden, Erfahrungen. Hannover: Schlütersche Verlagsgesellschaft. "
              linktext="Zum Verlag "
              url="https://buecher.schluetersche.de/de/risikomanagement-in-der-stationaeren-altenhilfe,570348467.html"
            ></Book>
            <Book
              number="1"
              citation="Barth Frazzetta, C. (2020). Eine positive Fehlerkultur etablieren. Deutsches Ärzteblatt, 117(7), 346–347."
              linktext="Zum Artikel "
              url="https://www.aerzteblatt.de/archiv/212527/Risikomanagement-Eine-positive-Fehlerkultur-etablieren#:~:text=Barth%20Frazzetta%2C%20Cristina&text=In%20vielen%20Organisationen%20wird%20noch,sind%20dabei%20vor%20allem%20F%C3%BChrungskr%C3%A4fte."
            ></Book>
            <Book
              number="1"
              citation="Beck Aktienrechtskommentar, 16. Aufl., 2022"
              linktext="Zum Verlag "
              url="https://beck-online.beck.de/Dokument?vpath=bibdata%2Fkomm%2Fhuefkoaktg_16%2Faktg%2Fcont%2Fhuefkoaktg.aktg.htm"
            ></Book>
            <Book
              number="1"
              citation="Bestvater, K. (2022). Fehlerkultur in Organisationen: Eine Organisationsethnografische Studie in der Stationären Altenpflege. Organisation und Pädagogik Ser: v.33. Wiesbaden: Springer."
              linktext="Zum Verlag "
              url="https://link.springer.com/book/10.1007/978-3-658-36653-7"
            ></Book>
            <Book
              number="2"
              citation="Blonski, H. (2014): Aktualität und Bedeutung des Risikomanagements für Einrichtungen in der Altenpflege"
              linktext="Zum PDF "
              url="http://bsimgx.schluetersche.de/upload1045314716766002152.pdf"
            ></Book>

            <Book
              number="3"
              citation="Borutta, M. (2014): Hohe Zuverlässigkeit – Risikomanagement in der Pflege nach dem Achtsamkeitsansatz der HRO-Prinzipien"
              linktext="Zum Verlag "
              url="https://buecher.schluetersche.de/de/risikomanagement-in-der-stationaeren-altenhilfe,570348467.html"
            ></Book>

            <Book
              number="4"
              citation="Brühwiler, B. (2016). Risikomanagement als Führungsaufgabe: Umsetzung bei strategischen Entscheidungen und operationellen Prozessen"
              linktext="Zum Verlag "
              url="https://www.haupt.ch/buecher/soziales-wirtschaft/risikomanagement-als-fuehrungsaufgabe.html"
            ></Book>
            <Book
              number="4"
              citation="Chandler, A.D. (1962) Strategy and Structure: Chapters in the History of American Enterprise. Boston: MIT Press. "
              linktext="Zum Verlag "
              url="https://mitpress.mit.edu/9780262530095/strategy-and-structure/"
            ></Book>
            <Book
              number="5"
              citation="Chrissis, M.B., Konrad, M., Shrum, S. (2006). CMMI. Richtlinien für Prozess-Integration und Produkt-Verbesserung"
              linktext="Zum PDF "
              url="https://resources.sei.cmu.edu/asset_files/WhitePaper/2009_019_001_29023.pdf"
            ></Book>

            <Book
              number="6"
              citation="Diederichs, M., Form, S.,Reichmann, T. (2004). Standard zum Risikomanagement"
              linktext="Zum PDF "
              url="https://www.beck-elibrary.de/10.15358/0935-0381-2004-4-5-1/titelei-inhaltsverzeichnis-jahrgang-16-2004-heft-4-5?page=1"
            ></Book>
            <Book
              number="6"
              citation="Ebner, G., Heimerl, P. & Schüttelkopf, E. M. (2008). Fehler – Lernen – Unternehmen: Wie Sie die Fehlerkultur und Lernreife Ihrer Organisation wahrnehmen und gestalten. Frankfurt/Main, Berlin, Bern, Wien: Lang."
              linktext="Zum Verlag "
              url="https://www.peterlang.com/document/1054018"
            ></Book>
            <Book
              number="7"
              citation="Simoni, F. T., Hauser, I.,  Bärtschi, H. (2022). Handbuch Schweizer Aktienrecht"
              linktext="Zum Verlag "
              url="https://www.helbing.ch/de/detail/ISBN-9783719044770/Handbuch-Schweizer-Aktienrecht"
            ></Book>

            <Book
              number="8"
              citation="Grottel (2022). §315 HGB Rn. 243; in: Beckscher Bilanzkommentar, 13. Auf."
              linktext="Zum Verlag "
              url="https://beck-online.beck.de/?vpath=bibdata%2Fkomm%2FBeBiKo_13%2FHGB%2Fcont%2FBeBiKo%2EHGB%2Ep315%2Ehtm"
            ></Book>

            <Book
              number="9"
              citation="Hanke, F. (2014): Risikomanagement in der Arzneimittelversorgung chronisch kranker Senioren – Aspekte einer geriatrischen Pharmazie"
              linktext="Zum Verlag "
              url="https://buecher.schluetersche.de/de/risikomanagement-in-der-stationaeren-altenhilfe,570348467.html"
            ></Book>

            <Book
              number="10"
              citation="Hunziker, S., Henrizi, P., Hilsbos, A., Schneeberger, T. (2022): Quick Guide: Ganzheitliches Risk Management im Krankenhaus – Praxisleitfaden für Implementierung und Umsetzung"
              linktext="Zum Verlag "
              url="https://www.springerprofessional.de/quick-guide-ganzheitliches-risk-management-im-krankenhaus/20346704"
            ></Book>
            <Book
              number="10"
              citation="Justenhoven, Küster & Deicke (2022) §317 HGB Rn. 113; in: Beckscher Bilanzkommentar 13. Aufl."
              linktext="Zum Verlag "
              url="https://www.beck-shop.de/beckscher-bilanz-kommentar/product/31600063"
            ></Book>
            <Book
              number="11"
              citation="Kämmer, K. (2014). Pflegerisches Risikomanagement; in: Blonski, H. (Hrsg.) (2014), Risikomanagement in der stationären Altenpflege: Anforderungen, Methoden, Erfahrungen. Hannover: Schlütersche Verlagsgesellschaft."
              linktext="Zum Verlag "
              url="https://buecher.schluetersche.de/de/risikomanagement-in-der-stationaeren-altenhilfe,570348467.html"
            ></Book>

            <Book
              number="12"
              citation="Kirchner, M. (2002): Risikomanagement: Problemaufriss und praktischen Erfahrungen unter Einbeziehung eines sich ändernden unternehmerischen Umfelds"
              linktext="Zu Amazon "
              url="https://www.amazon.de/Risikomanagement-Michael-Kirchner/dp/3879886806"
            ></Book>

            <Book
              number="13"
              citation="Lux, W. & Kohn, W. (2005). Risikomanagement –Konzept und Realisierung; in: CM Controller Magazin, Heft 6, S. "
              linktext="Zum PDF "
              url="https://www.haufe.de/download/controller-magazin-ausgabe-62005-controller-magazin-139288.pdf"
            ></Book>

            <Book
              number="14"
              citation="Lux, W. & Steiner, M. (2008). Strategieumsetzung, Risikomanagement und interne Kontrollsysteme; in: Journal of Performance Management, Nr. 4."
              linktext="Zu ResearchGate "
              url="https://www.researchgate.net/publication/315642741_Strategieumsetzung_Risikomanagement_und_Interne_Kontrollsysteme"
            ></Book>
            <Book
              number="15"
              citation="Osterwalder, A. & Pigneur, Y. (2010): Business Model Generation. A Handbook for Visionaries, Game Changers and Challengers. London: Pearson."
              linktext="Zu Amazon "
              url="https://www.amazon.de/Business-Model-Generation-Spielver%C3%A4nderer-Herausforderer/dp/359339474X"
            ></Book>
            <Book
              number="15"
              citation="Porter, M. (1980): Competitive Strategy: Techniques for Analyzing Industries and Competitors, The Free Press"
              linktext="Zu Amazon "
              url="https://www.amazon.de/-/en/Michael-Porter/dp/0029253608"
            ></Book>
            <Book
              number="15"
              citation="Reiner, C. (2014). Risikomanagement in Hauswirtschaft und Küche; in: Blonski, H. (2014) Hrsg.: Risikomanagement in der stationären Altenpflege: Anforderungen, Methoden, Erfahrungen. Hannover: Schlütersche Verlagsgesellschaft. "
              linktext="Zum Verlag "
              url="https://buecher.schluetersche.de/de/risikomanagement-in-der-stationaeren-altenhilfe,570348467.html"
            ></Book>
            <Book
              number="15"
              citation="Richiger, U. & Müllener, M. (o.J.). Das Zusammenspiel zwischen Qualitäts- und Risikomanagement – Synergien nutzen, Redundanzen vermeiden; in: QZ-Online "
              linktext="Zum Artikel "
              url="https://www.qz-online.de/a/grundlagenartikel/das-zusammenspiel-zwischen-qualitaets-un-320314"
            ></Book>
            <Book
              number="16"
              citation="Rosenkranz, F. & Missler-Behr, M. (2005). Unternehmensrisiken erkennen und managen: Einführung in die quantitative Planung"
              linktext="Zum Verlag "
              url="https://link.springer.com/book/10.1007/b137724"
            ></Book>
            <Book
              number="16"
              citation="Schein, E. H. (2018). Organisationskultur und Leadership (5. Aufl.). München: Franz Vahlen"
              linktext="Zum Verlag "
              url="https://www.vahlen.de/schein-schein-organisationskultur-leadership/product/22325676"
            ></Book>
            <Book
              number="16"
              citation="Tegtmeier, U. & Wiedensohler, R. (2013). Risikomanagement, Fehlerkultur und Patientensicherheit. Endo-Praxis, 29(01), 10–15. "
              linktext="Zur Zeitschrift "
              url="https://www.thieme-connect.com/products/ejournals/abstract/10.1055/s-0033-1336411"
            ></Book>
            <Book
              number="18"
              citation="Waibel, R., Käppeli, M. (2006). Betriebswirtschaft für Führungspersonen – Die Erfolgslogik des unternehmerischen Denkens und Handelns"
              linktext="Zum Verlag "
              url="https://www.versus.ch/produkt/214"
            ></Book>
            <Book
              number="18"
              citation="Winsvold Prang, I. & Jelsness-Jørgensen, L.-P. (2014). Should I report? A qualitative study of barriers to incident reporting among nurses working in nursing homes. Geriatric Nursing, 35(6), 441–447. doi:10.1016/j.gerinurse.2014.07.003"
              linktext="Zum Artikel "
              url="https://www.sciencedirect.com/science/article/abs/pii/S0197457214003279?via%3Dihub"
            ></Book>
          </div>
        </div>
      </>
    );
  }
}

export default Bibliothek;
