import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import Info from "./components/Info";
import { GrundlagenRisikomanagement } from "./components/grundlagen/GrundlagenRisikomanagement";
import Navbar from "./components/Navbar";
import Prozess from "./components/Prozess";
import Glossar from "./components/Glossar";
import Identifikation from "./components/identifikation/Identifikation";
import Risikokatalog from "./components/identifikation/Risikokatalog";
import Schnittstellen from "./components/grundlagen/Schnittstellen";
import Rechtliches from "./components/grundlagen/Rechtliches";
import Bibliothek from "./components/Bibliothek";
import Templates from "./components/Templates";
import Wertkettenanalyse from "./components/identifikation/Wertkettenanalyse";
import Brainstorming from "./components/identifikation/Brainstorming";
import WorldCafe from "./components/identifikation/WorldCafe";
import Prozesskettenanalyse from "./components/identifikation/Prozesskettenanalyse";
import Analyse from "./components/Analyse/Analyse";
import Risikomatrix from "./components/Analyse/Risikomatrix";
import Dokumentenanalyse from "./components/identifikation/Dokumentenanalyse";
import Fehlerbaumanalyse from "./components/identifikation/Fehlerbaumanalyse";
import Beschwerdesystem from "./components/identifikation/Beschwerdesystem";
import Quantifizierung from "./components/Analyse/Quantifizierung";
import Verankerung from "./components/verankerung/Verankerung";
import Search from "./components/functions/Search";
import ScrollToTop from "./components/functions/ScrollToTop";
import Szenariotechnik from "./components/Analyse/Szenariotechnik";
import Fehlermeldesysteme from "./components/Analyse/Fehlermeldesysteme";
import Wechselwirkungen from "./components/Analyse/Wechselwirkungen";
import Bewältigung from "./components/bewältigung/Bewältigung";
import Überwachung from "./components/überwachung/Überwachung";
import Hilfe from "./components/Hilfe";




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HashRouter>
      <Navbar></Navbar>
      <Routes>
      <Route path="Search" element={<Search />} />
      <Route path="ScrollToTop" element={<ScrollToTop />} />
        <Route path="/" element={<App />} />
        <Route path="Info" element={<Info />} />
        <Route
          path="GrundlagenRisikomanagement"
          element={<GrundlagenRisikomanagement />}
        />
        <Route path="Prozess" element={<Prozess />} />
        <Route path="Glossar" element={<Glossar />} />
        <Route path="Identifikation" element={<Identifikation />} />
        <Route path="Risikokatalog" element={<Risikokatalog />} />
        <Route path="Schnittstellen" element={<Schnittstellen />} />
        <Route path="Rechtliches" element={<Rechtliches />} />
        <Route path="Bibliothek" element={<Bibliothek />} />
        <Route path="Templates" element={<Templates />} />
        <Route path="Wertkettenanalyse" element={<Wertkettenanalyse />} />
        <Route path="Brainstorming" element={<Brainstorming />} />
        <Route path="WorldCafe" element={<WorldCafe />} />
        <Route path="Prozesskettenanalyse" element={<Prozesskettenanalyse />} />
        <Route path="Analyse" element={<Analyse />} />
        <Route path="Risikomatrix" element={<Risikomatrix />} />
        <Route path="Dokumentenanalyse" element={<Dokumentenanalyse />} />
        <Route path="Fehlerbaumanalyse" element={<Fehlerbaumanalyse />} />
        <Route path="Beschwerdesystem" element={<Beschwerdesystem />} />
        <Route path="Quantifizierung" element={<Quantifizierung/>} />
        <Route path="Verankerung" element={<Verankerung/>} />
        <Route path="Szenariotechnik" element={<Szenariotechnik/>} />
        <Route path="Fehlermeldesysteme" element={<Fehlermeldesysteme/>} />
        <Route path="Wechselwirkungen" element={<Wechselwirkungen/>} />
        <Route path="Überwachung" element={<Überwachung/>} />
        <Route path="Bewältigung" element={<Bewältigung/>} />
        <Route path="Hilfe" element={<Hilfe/>} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
