import React from "react";
import { Link } from "react-router-dom";

function Card({ icon, title, link }) {
  let path = "/icon/" + icon + ".svg";
  return (
    <Link  to={link}>
      <div id={title} className="tile align-items-center">
        <div className="container container-right d-flex align-items-center tile-icon">
          <img className="tile-icon icon" alt="" src={path} id={icon} />
          </div>
          <div className="d-flex align-items-center container tile-title">
          <p>{title}</p>
          </div>
      </div>
    </Link>
  );
}

export default Card;
