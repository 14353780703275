import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname, hash, key} = useLocation();

  useEffect(() => {
     // if not a hash link, scroll to top
     if (hash === '') {
      window.scrollTo(0, 0);
      document.querySelectorAll('img').forEach(function(img){
        img.onerror = function(){this.style.display='none';};
       })
    }
    // else scroll to id
    else {
      document.querySelectorAll('img').forEach(function(img){
        img.onerror = function(){this.style.display='none';};
       })
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); 

  
    
 

  return null;
}

export default ScrollToTop;