import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../functions/ScrollToTop";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";
import TemplateCard from "../design/TemplateCard";

const text = hyphenated(
  `<p>
  In Pflegeheimen und ähnlichen Einrichtungen ist es wichtig, unerwünschte Ereignisse wie Unfälle oder Risiken sorgfältig zu bewerten und zu behandeln. Dazu können Mitarbeitende, Bewohnende, Angehörige und andere Personen diese Ereignisse anonym oder namentlich melden. Es ist entscheidend, einen klaren Prozess für den Umgang mit solchen Vorfällen festzulegen. Diese Ereignisse können in verschiedene Kategorien wie Unfälle, Gewalttaten, Suizidversuche und weitere eingeteilt werden.
  </p>`,
  { language: de }
);

class Fehlermeldesysteme extends Component {
  static name = "Fehlermeldesysteme";
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Analyse">
            &lt; Zurück zur Risikoanalyse und -bewertung
          </Link>
          <br></br>
          <br></br>
          <h1>
            Fehlermeldesysteme – Critical Incidents Reporting Systems (CIRS)
          </h1>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
          <TemplateCard
            style="prozess"
            title="Formular unerwünschte Ereignisse"
            description="Ein Muster-Meldeformular für unerwünschte Ereignisse"
            format="Word"
            image="Unerwuenschte_Ereignisse"
            download="Template_Muster_Formular_Unerwuenschte_Ereignisse.docx"
          />
        </div>
      </>
    );
  }
}

export default Fehlermeldesysteme;
