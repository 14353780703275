import React from "react";
import { Link } from "react-router-dom";

function Card({ icon, title, intro, link, active }) {
  let path = "/icon/" + icon + ".svg";
  let classActive = "row-card card_custom" + active;
  return (
    <Link className="a-prozess" to={link}>
      <div id={title} className="wrapper d-flex align-items-center">
        <div className="container container-right d-flex align-items-center">
          <div className={classActive}>
            <div className="row row-process">
              <div className="col-4 d-flex flex-wrap align-items-center icon-div">
                <img className="icon" alt="" src={path} id={icon} />
              </div>
              <div className="col-6 d-flex flex-column align-items-start content-card">
                <h2>{title}</h2>
                <p className="intro">{intro}</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default Card;
