import React from "react";
import { Component } from "react";
import ScrollToTop from "../functions/ScrollToTop";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";
import TemplateCard from "../design/TemplateCard";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  backgroundColor: "transparent",
  marginLeft: "30px",
  paddingRight: "30px",
  display: "block",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "1.2rem", color: "darkgrey" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  marginLeft: "-45px",
  "& .MuiAccordionSummary-content": {
    marginLeft: "5px",
    Typography: {
      fontSize: "1.0rem",
    },
  },
}));


const strategischesManagement = hyphenated(
  `Strategisches Management in der Betriebswirtschaftslehre umfasst die Entwicklung, Planung und Umsetzung von organisatorischen Zielen. Es muss eng mit dem Risikomanagement verknüpft sein, da sämtliche Ziele sowohl Chancen als auch Risiken beinhalten. Planungshorizonte erstrecken sich normalerweise über zwei bis fünf Jahre. Wichtige Beiträge zur Managementtheorie stammen von Chandler (1962), Ansoff (1965) und Andrews (1971).<br /><br />
  Es gibt vereinfachende Matrizen für generische Strategien wie Ansoffs Produkt-Markt-Matrix und die BCG-Matrix der Boston Consulting Group. Michael Porters generische Wettbewerbsstrategien (Differenzierung, Kostenführerschaft, Fokussierung) sind ebenfalls bekannt.<br /><br />
  Strategieentwicklung erfordert in der Regel die folgenden Schritte:<br /><br />
  <ol>
  <li>Definition von Mission, Vision und Werten. </li>
  <li>Identifikation allgemeiner Trends (PESTEL-Analyse). </li>
  <li>Definition des Zielmarktes und strategischer Geschäftsfelder. </li>
  <li>Durchführung einer Wettbewerbsanalyse (Porters Five Forces). </li>
  <li>Durchführung einer SWOT-Analyse. </li>
  <li>Festlegung strategischer Ziele. </li>
  </ol>
  Strategische Ziele sollten messbar sein und erfordern Massnahmen zur Umsetzung. Hierbei sind Instrumente wie die Balanced Scorecard, Kennzahlensysteme oder Management-Cockpits hilfreich. 
  <br /><br />`,
  { language: de }
);

const qualitaetsmanagement = hyphenated(
  `Qualitätsmanagement und Risikomanagement sind separate, aber ergänzende Ansätze. Das Risikomanagement zielt auf ungeplante Abweichungen und Entscheidungen unter Unsicherheit ab, während das Qualitätsmanagement Prozesse gemäss Anforderungen plant, steuert und optimiert, um die Produkt- oder Dienstleistungsqualität und die Kundenzufriedenheit zu verbessern. Qualitätsmanagement verwendet Qualitätsindikatoren zur Leistungsmessung und stellt sicher, dass Qualitätsaspekte in Arbeitsabläufe integriert werden. <br /><br />
  Qualitätsmanagement unterstützt die Verminderung von Risiken, indem es bei der Risikoidentifikation, -steuerung und -bewältigung hilft. Gesetzliche Vorschriften haben einheitliche Qualitätsstandards für die Pflege eingeführt und Pflegeeinrichtungen zur Implementierung eines Qualitätsmanagements verpflichtet. Viele Einrichtungen haben Qualitätszertifikate wie die DIN ISO 9001 erworben. <br /><br />
  Bei der Zertifizierung bewerten Pflegeeinrichtungen fünf Hauptbereiche:<br /><br />
  <ul>
  <li>Wirtschaftlicher Erfolg (anhand der Umsatzziele, der Auslastung, der Kosten)</li>
  <li>Zufriedenheit der Pflegebedürftigen (einmal jährlich werden Bewohner und Angehörige befragt)</li>
  <li>Zufriedenheit der Mitarbeiter (Arbeitsatmosphäre, Arbeitsbedingungen usw.)</li>
  <li>Beschwerdemanagement (Beschwerdebogen für Bewohner und Angehörige)</li>
  <li>Ergebnisse externer Prüfungen (z. B. durch MDK, Heimaufsicht oder Gesundheitsamt)</li>
  </ul>
  Risikomanagement und Qualitätsmanagement ergänzen sich, obwohl die Abgrenzung nicht immer eindeutig ist.<br /><br />
  `,
  { language: de }
);

//const complianceManagement = hyphenated(
// `Compliance bedeutet die Einhaltung von gesetzlichen Bestimmungen, regulatorischen Standards und internen Regelwerken. Durch Compliance Management sollen die damit verbundenen Risiken, d.h. strafrechtliche oder regulatorische Konsequenzen bei Compliance-Verstössen minimiert werden. Compliance-Meldungen können auch Hinweise auf mögliche, noch unerkannte Risiken geben und unterstützen so den Prozess der Risikoidentifikation.
//  `,
//  { language: de }
//);

//const informationssicherheit = hyphenated(
//    `Insbesondere durch die zunehmende Digitalisierung in allen Bereichen nehmen die Anforderungen an Informationssicherheit und Datenschutz ständig zu. Im Rahmen eines Information Security Management Systems wird versucht, diesen komplexen Anforderungen zu begegnen. Dabei stehen die Aspekte Vertraulichkeit, Integrität, Verfügbarkeit von Informationen, Geschäftsprozessen, Anwendungen und ICT-Systemen im Zentrum.
//    `,
//    { language: de }
//);

  const versicherungsUndSchadenmanagement = hyphenated(
    `Das Abschliessen einer Versicherung ist ein typisches Beispiel für einen Risikotransfer – für das Überwälzen eines bestimmten Schadenspotenzials an Dritte – in diesem Fall an die Versicherungsgesellschaft. Haftpflichtschäden, Gebäude, Feuer und ähnliches können auf diese Weise abgedeckt werden. Eine Besonderheit bei Pflegeheimen besteht darin, dass Bewohnende zu Schaden kommen können – durch falsche Medikation, Unfälle, Übergriffe, mangelnde Sicherheitsmassnahmen und ähnliches. Eine Haftpflichtversicherung deckt in solchen Fällen zwar den finanziellen Schaden ab. Ethische Fragen und Reputationsschäden lassen sich jedoch nicht auf diese Weise versichern. Transferierte Risiken tauchen üblicherweise nicht mehr auf der Liste der relevanten Risiken auf. In diesem Fall sollte dies aber trotzdem geschehen, um auch den nicht direkt finanziell wirksamen Risiken Rechnung zu tragen.  <br /><br />
    `,
    { language: de }
  );

  const krisenstab = hyphenated(
    ` Ein Krisenstab ist eine Organisation innerhalb einer Einrichtung, die bei Katastrophen wie Pandemien die Rückkehr zur Normalität sicherstellt. Er verwendet Notfallpläne, um die Krise zu bewältigen, im Gegensatz zum Risikomanagement, das sich mit Risiken und Chancen befasst. Der Krisenstab klärt Situationen, führt Interventionen durch und kommuniziert Informationen. <br /><br />
    `,
    { language: de }
  );

  const leadership = hyphenated(
    ` In den letzten Jahrzehnten wurde der Begriff "Leadership" in der deutschen Sprache vor allem mit Führungsqualitäten, Leitung und Führung in Verbindung gebracht. Leadership umfasst die besonderen Merkmale, die Führungskräfte benötigen, um andere von ihren Werten, Zielen, Visionen und Handlungsweisen zu überzeugen und eine Vorbildfunktion einzunehmen. <br /><br />
    Es gibt verschiedene Führungsstile in der Literatur:
    <ul>
    <li>Autokratisch bzw. autoritär: Hier werden die Entscheidungen durch die Führungskraft zentral getroffen </li>
    <li>Partizipativ bzw. demokratisch: Entscheidungen werden nicht zentral getroffen, sondern die Gruppe wird einbezogen </li>
    <li>Laissez-faire: Entscheidungen werden an die Gruppe delegiert.</li>
    <li>Aufgabenorientierung: Konzentration auf sachliche Aufgaben, Ziele und Termine </li>
    <li>Beziehungsorientierung: Konzentration auf die Beziehungen innerhalb der Gruppe, Kommunikation und Vertrauen </li>
    </ul>
    Die Begriffe «Manager» und «Leader» werden im Alltag oft als Synonyme verwendet, aber sie unterscheiden sich in ihrer Arbeitsweise und ihren Zielen. Manager konzentrieren sich auf Strukturen und Systeme, während Leader sinnstiftende Ziele setzen und Mitarbeitende inspirieren. Leader legen Wert auf Beziehungen, schaffen Vertrauen und denken langfristig. Sie sollten strategische, unternehmerische, zwischenmenschliche und kognitive Kompetenzen haben. Eine erfolgreiche Führung kombiniert oft Leadership und Management, indem sie technokratische Ansätze mit den Eigenschaften des Leadership verbindet.
    <br /><br />
    `,
    { language: de }
  );

class Schnittstellen extends Component {
  static name = "Schnittstellen";
  render() {
    return (
      <>
        <ScrollToTop />
        <div className="App">
          <div className="content">
            <h1>Schnittstellen zu anderen Systemen und Funktionen</h1><br />
            <Accordion>
              <AccordionSummary>
                <h2>Strategisches Management</h2>
              </AccordionSummary>
              <div
                className="textContainer"
                dangerouslySetInnerHTML={{ __html: strategischesManagement }}
              ></div>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <h2>Qualitätsmanagement</h2>
              </AccordionSummary>
              <div
                className="textContainer"
                dangerouslySetInnerHTML={{
                 __html: qualitaetsmanagement,
                }}
              ></div>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <h2>Versicherungs- und Schadenmanagement</h2>
              </AccordionSummary>
              <div
                className="textContainer"
                dangerouslySetInnerHTML={{
                  __html: versicherungsUndSchadenmanagement,
                }}
              ></div>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <h2>Krisenstab</h2>
              </AccordionSummary>
              <div
                className="textContainer"
                dangerouslySetInnerHTML={{
                  __html: krisenstab,
                }}
              ></div>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <h2>Leadership</h2>
              </AccordionSummary>
              <div
                className="textContainer"
                dangerouslySetInnerHTML={{
                  __html: leadership,
                }}
              ></div>
            </Accordion>

          </div>
        </div>
      </>
    );
  }
}

export default Schnittstellen;
