import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../functions/ScrollToTop";
import TemplateCard from "../design/TemplateCard";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";

const text = hyphenated(
  `<p>
  Eine klassische Methode der Risikobeurteilung ist die Szenario-Technik bzw. Analyse. Sie beschränkt sich auf wenige, wichtige Einzelrisiken, für die dann üblicherweise drei Szenarien skizziert werden: Erwartetes Szenario, Best Case, Worst Case. Eine Gewichtung der jeweiligen Eintrittswahrscheinlichkeiten führt zu einer Gesamtrisikobewertung bzw. -einschätzung. 
 
  </p>`,
  { language: de }
);

class Szenariotechnik extends Component {
  static name = "Szenariotechnik";
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Analyse">
            &lt; Zurück zur Risikoanalyse und -bewertung
          </Link>
          <br></br>
          <br></br>
          <h1>Szenariotechnik</h1>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </>
    );
  }
}

export default Szenariotechnik;
