import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import Card from "../design/Card";
import ScrollToTop from "../functions/ScrollToTop";
import Button from "@mui/material/Button";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";
import TemplateCard from "../design/TemplateCard";

/**
 *  Overview for "verankerung".
 *  One Card for every Subsite.
 *  Subsites need to be linked via index. js => <Route path="Subsite" element={<Subsite />} />
 */

const text = hyphenated(
  `<p>
  Die Überwachung von Restrisiken ist entscheidend, da sie sich auf das Unternehmensergebnis auswirken können. Eine effektive Risikoüberwachung erfordert die Verwendung geeigneter Kennzahlen und Frühwarnindikatoren. Die Risikokommunikation sollte alle Ebenen in der Organisation kontinuierlich über die Risikosituation informieren und das Risikobewusstsein fördern. Die Risikoberichterstattung umfasst Massnahmen zur Erfassung, Verarbeitung und Speicherung von risikorelevanten Informationen und richtet sich an interne und externe Empfänger. Externe Berichte sind oft Teil des Jahresberichts und prägnanter. Interne Berichte sind detaillierter und variieren je nach Zielgruppe. Eine transparente Risikoberichterstattung ist entscheidend für das Risikomanagement. 

  </p>`,
  { language: de }
);

class Überwachung extends Component {
  static name = "Überwachung";
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Prozess">
            &lt; Zurück zum Prozess
          </Link>
          <br />
          <br />
          <h1>Überwachung + Kommunikation</h1>
          <br></br>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
          <br></br>
          <TemplateCard
            style="prozess"
            title="Risikobericht"
            description="Vorlage für einen Risikobericht."
            format="Word"
            image="Risikobericht_docx"
            download="Template_Muster_Risikobericht.docx"
          />
          <TemplateCard
            style="prozess"
            title="Risikobericht"
            description="Vorlage für die Präsentation des Risikoberichts."
            format="PowerPoint"
            image="Risikobericht_pptx"
            download="Template_Risikobericht_Detailliert.pptx"
          />
        </div>
      </>
    );
  }
}

export default Überwachung;
