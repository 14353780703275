import React from "react";
import { Component } from "react";
import ScrollToTop from "../functions/ScrollToTop";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";
import TemplateCard from "../design/TemplateCard";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  backgroundColor: "transparent",
  marginLeft: "30px",
  paddingRight: "30px",
  display: "block",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "1.2rem", color: "darkgrey" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  marginLeft: "-45px",
  "& .MuiAccordionSummary-content": {
    marginLeft: "5px",
    Typography: {
      fontSize: "1.0rem",
    },
  },
}));

const schweiz = hyphenated(
  `Pflegeheime können öffentlich und privatrechtlich organisiert sein. Das Gemeinwesen bestimmt die
  Rechtsform im Rahmen des kantonalen Rechts. In den meisten Kantonen bestehen sowohl öffentliche
  als auch private Pflegeheime.<br><br>
  Auf Bundes- und Kantonsebene gibt es zahlreiche Regelungen, die Alters- und Pflegeheime unabhängig
  von der gewählten Rechtsform einzuhalten haben. Die Bewilligungsprüfung und die Regelungen zur
  Aufsicht bezwecken grundsätzlich, dass Alters- und Pflegeheime ein funktionierendes Qualitäts- und
  Risikomanagement gewährleisten müssen. Schweizweit und in einzelnen Kantonen bestehen
  zunehmend Regelungen, die mehr oder weniger detaillierte Qualitätsstandards für Alters- und
  Pflegeheime vorsehen. Das Ziel besteht darin,sowohl wirtschaftliche Risiken als auch Risiken im Umgang
  mit Personal und Bewohnenden zu vermeiden.<br><br>
  In privatrechtlicher Hinsicht sind insbesondere der Verein, die Stiftung, die Aktiengesellschaft, die
  GmbH sowie die Genossenschaft mögliche Rechtsformen für ein Pflegeheim. Als mögliche öffentlichrechtliche Organisationsformen kommen insbesondere die öffentlich-rechtliche Anstalt, die
  Körperschaft oder der Zweckverband in Frage.<br><br>`,
  { language: de }
);

const deutschland = hyphenated(
  `Auch im deutschen Recht gilt es zu unterscheiden, ob die Pflicht besteht, ein Risikomanagementsystem
  einzuführen. Falls dies zutrifft, bestehen Regelungen, wie darüber zu berichten ist und inwiefern die
  Berichterstattung überprüft wird.<br><br>
  Das deutsche Recht kennt konkrete Bestimmungen, die sich zur Risikobeurteilung äussern. Der
  Gesetzgeber erliess das «Gesetz zur Kontrolle und Transparenz» (KonTraG) sowie das «Gesetz zur
  Stärkung der Finanzmarktintegrität» (FISG)<br><br>`,
  { language: de }
);

const oesterreich = hyphenated(
  `Die rechtlichen Bestimmungen bezüglich des Risikomanagements für Unternehmen sind vorwiegend
  im Unternehmensgesetzbuch (UGB), im Aktiengesetz (AktG) und im GmbH-Gesetz (GmbHG)
  geregelt.105 Spezifische Regelungen für Stiftungen und Vereine sind in deren gesetzlichen Vorgaben
  (Privatstiftungsgesetz, Vereinsgesetz) nicht geregelt. Hier ist jedoch davon auszugehen, dass die zuvor
  genannten Regelungen auch auf diese beiden Organisationsformen anzuwenden sind.<br><br>
  Zusätzlich stellt der Österreichische Arbeitskreis für Corporate Governance den börsennotierten
  Unternehmen ein freiwilliges Regelwerk für die Unternehmensführung und die
  Unternehmenskontrolle zur Verfügung: den Österreichischen Corporate Governance Kodex (ÖCGK).
  Dieser umfasst neben den rechtlichen Grundlagen auch einzuhaltende Regelungen und Empfehlungen.
  Abweichungen von den Regelungen müssen erklärt und begründet werden.<br><br>`,
  { language: de }
);

const normen = hyphenated(
  `
  Es gibt nationale und internationale Normen für das Risikomanagement. Die ISO-Norm 31000 ist ein internationaler Standard, der allgemeine Grundsätze und den Risikomanagementprozess beschreibt. Sie betont die Bedeutung von Kennzahlen als Frühwarnindikatoren.<br /><br />
  Die österreichische ONR 49000 ist ebenfalls relevant. Sie fördert ein gemeinsames Verständnis und die Integration von Risikomanagement in Managementsysteme. Diese Norm wurde in die ISO 31000 integriert und deckt zusätzlich Krisen- und Kontinuitätsmanagement ab. Sie stellt auch Anforderungen an die Ausbildung und Zertifizierung von Risikomanagern.<br /><br />
  Beide Normen sind wichtige Leitfäden für Unternehmen, die ihr Risikomanagement verbessern möchten. <br /><br />
  <b>COSO-Modell</b><br /><br />
  Das COSO-Modell ist ein bekanntes Rahmenwerk für interne Kontrollsysteme (IKS). Es integriert seit 2004 auch das Risikomanagement (COSO II). Das Modell betrachtet verschiedene Aspekte wie Strategie, Betrieb, Berichterstattung und Vorschriften. Es umfasst acht Komponenten: Kontrollumfeld, Risikobeurteilung, Kontrollaktivitäten, Information und Kommunikation, Überwachung und Veränderungsmanagement. Diese Elemente sind entscheidend für ein wirksames IKS, das Risiken erkennt, Massnahmen überwacht und eine effektive Kommunikationskultur aufrechterhält, um Veränderungen zu bewältigen. Das COSO-Modell ist ein nützliches Instrument für Unternehmen zur Verbesserung ihrer Kontroll- und Risikomanagementprozesse und zur Anpassung an sich ändernde Anforderungen.<br /><br />
  <br><br>
  
  `,
  { language: de }
);

class Rechtliches extends Component {
  static name = "Rechtliches";
  render() {
    return (
      <>
        <ScrollToTop />
        <div className="App">
          <div className="content">
            <h1>Gesetzliche Grundlagen und Normen</h1>
            <br />
            <Accordion>
              <AccordionSummary>
                <h2>Gesetzliche Grundlagen in der Schweiz</h2>
              </AccordionSummary>
              <div
                className="textContainer"
                dangerouslySetInnerHTML={{ __html: schweiz }}
              ></div>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <h2>Gesetzliche Grundlagen in Deutschland</h2>
              </AccordionSummary>
              <div
                className="textContainer"
                dangerouslySetInnerHTML={{
                  __html: deutschland,
                }}
              ></div>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <h2>Gesetzliche Grundlagen in Österreich</h2>
              </AccordionSummary>
              <div
                className="textContainer"
                dangerouslySetInnerHTML={{
                  __html: oesterreich,
                }}
              ></div>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <h2>Normen</h2>
              </AccordionSummary>
              <div
                className="textContainer"
                dangerouslySetInnerHTML={{
                  __html: normen,
                }}
              ></div>
            </Accordion>

            <TemplateCard
              style="prozess"
              title="Pflegerische Risiken"
              description="Auswahl klinisch-haftungsrechtlicher Risiken in der Pflege."
              format="Excel"
              image="Pflegerische_Risiken"
              download="Template_pflegerische_Risiken.xlsx"
            />
            {/*<TemplateCard
              style="prozess"
              title="Leitfaden"
              description="Der komplette Leitfaden für ganzheitliches Risikomanagement in Pflegeheimen."
              format="PDF"
              image="Leitfaden"
              download="Leitfaden_Ganzheitliches_Risikomanagement_für_Pflegeeinrichtungen.pdf"
              />*/}
          </div>
        </div>
      </>
    );
  }
}

export default Rechtliches;
