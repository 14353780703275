import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../functions/ScrollToTop";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";
import TemplateCard from "../design/TemplateCard";

const text = hyphenated(
  `<p>
  Risikotragfähigkeit bedeutet, dass das Unternehmen bzw. die Organisation Verluste durch Gefahren abfedern kann, ohne insolvent zu werden. Beim Risikomanagement ist die Existenzsicherung entscheidend, und die Gesamtheit der Risiken sollte festgelegte Grenzen nicht überschreiten. Risiken werden objektiv anhand von Eintrittswahrscheinlichkeit und Schadensausmass bewertet, wobei individuelle Skalen festgelegt werden. Eine mögliche Schwäche besteht darin, dass Risiken mit geringer Wahrscheinlichkeit und hohem Schadenspotenzial möglicherweise unterschätzt werden.
  </p>`,
  { language: de }
);

class Quantifizierung extends Component {
  static name = "Quantifizierung";
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Analyse">
            &lt; Zurück zur Risikoanalyse und -bewertung
          </Link>
          <br></br>
          <br></br>
          <h1>Quantifizierung von Risiken</h1>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
          <TemplateCard
            style="prozess"
            title="Risikokatalog"
            description="Risikokatalog mit exemplarischen Einträgen und automatischer Erstellung einer Risikomatrix."
            format="Excel"
            image="Risikokatalog"
            download="Template_Risikokatalog.xlsx"
          />
        </div>
      </>
    );
  }
}

export default Quantifizierung;
