import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import Card from "../design/Card";
import ScrollToTop from "../functions/ScrollToTop";
import Button from "@mui/material/Button";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";
import TemplateCard from "../design/TemplateCard";


const text = hyphenated(
  `<p>
  In der letzten Phase des Risikomanagementprozesses geht es um die Bewältigung von Risiken. Dies umfasst präventives Risikomanagement, Schadenmanagement, Risikofinanzierung und Risikoakzeptanz. Präventives Risikomanagement zielt darauf ab, Risiken zu vermeiden oder zu reduzieren, indem menschliche, technische und organisatorische Faktoren berücksichtigt werden. Schadenmanagement bereitet die Organisation auf Schäden vor und beinhaltet Notfall- und Krisenmanagement. Risikofinanzierung bezieht sich auf die Nutzung von Versicherungen. Risikobegrenzung umfasst Risikostreuung und -limitierung durch Diversifikation und Festlegung von Risikogrenzen. Risikoüberwälzung und -transfer erfolgen durch Verträge oder Versicherungen, um Risiken auf Dritte zu übertragen. Risikoakzeptanz betrifft nicht vermeidbare Restrisiken, die mit angemessenen Vorsorgemassnahmen relativiert werden sollten. Die Risikosteuerung erfordert eine regelmässige Überprüfung und Anpassung von Massnahmen, wobei Scoring-Modelle bei der Auswahl helfen können.
</p>`,
  { language: de }
);

class Bewältigung extends Component {
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Prozess">
            &lt; Zurück zum Prozess
          </Link>
          <br />
          <br />
          <h1>Risiko-Bewältigung</h1>
          <br></br>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
          <br></br>
          <TemplateCard
              style="prozess"
              title="Nutzwertanalyse"
              description="Vorlage für eine Nutzwertanalyse inkl. Paarvergleich."
              format="Excel"
              image="Nutzwertanalyse"
              download="Template_Nutzwertanalyse_Paarvergleich.xlsx"
            />

        </div>
      </>
    );
  }
}

export default Bewältigung;
