import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GrundlagenRisikomanagement } from "../grundlagen/GrundlagenRisikomanagement";
import Rechtliches from "../grundlagen/Rechtliches";
import Schnittstellen from "../grundlagen/Schnittstellen";


/**
 * Search component that renders search results based on defined modules 
 * (please import and specify them in the Navbar.js component) and searchQuery.
 * 
 * @param {Object[]} modules - An array of modules.
 * @param {string} searchQuery - The search query entered by the user.
 * @returns {JSX.Element} - The rendered search results.
 */



const Search = ({ modules, searchQuery, handleClick }) => {
  const { pathname, hash, key } = useLocation();
  const [result, setResult] = useState([]);
  const [renderedResults, setRenderedResults] = useState([]);
 
  const handleLinkClick = () => {
    handleClick();
  };

  useEffect(() => {
    const renderedComponents = modules.map((Module) => {
      const instance = new Module();
      return instance.render();
    });

    const newResult = renderedComponents.map((component, index) => {
      const value = findDangerouslySetInnerHTMLContent(component).replace(
        /­/g,
        ""
      );
      const key = modules[index].name.replace(/([a-z])([A-Z])/g, "$1 $2");

      return { key, value };
    });

    setResult(newResult);
  }, [modules, pathname, hash, key, searchQuery]);

  useEffect(() => {
    const filteredResults = result.filter((item) => {
      if (searchQuery === "") return false;
      return (
        item.key.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.value.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    const updatedRenderedResults = filteredResults.map((item) => (
      <div class="searchLink" key={item.key}>
        <Link to={item.key.replace(/\s/g, "")} onClick={handleLinkClick}>
          {item.key}
        </Link><br/>
        {item.value.substring(0, 200).replace("<p>", "").replaceAll("<br>", "").replaceAll("<h2>", "").replaceAll("</h2>", "").replaceAll("</br>", "")}....
      </div>
    ));

    setRenderedResults(updatedRenderedResults);
  }, [result, searchQuery]);

 

  /**
   * Recursively finds the content inside a component's `dangerouslySetInnerHTML` prop.
   * (Displayed Text for each page of the app can be found there)
   * @param {JSX.Element} component - The component to search for content.
   * @returns {string} - The content found within the component.
   */

  const findDangerouslySetInnerHTMLContent = (component) => {
    if (
      component &&
      component.props &&
      component.props.dangerouslySetInnerHTML
    ) {
      return component.props.dangerouslySetInnerHTML.__html || "";
    }

    let content = "";

    if (
      component &&
      component.props &&
      Array.isArray(component.props.children)
    ) {
      for (const child of component.props.children) {
        content += findDangerouslySetInnerHTMLContent(child);
      }
    }

    if (component && component.props && component.props.children) {
      content += findDangerouslySetInnerHTMLContent(component.props.children);
    }
    content = ` ${content} `;

    return content;
  };

  return <div id="results">{renderedResults}</div>;
};

export default Search;
