import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import Card from "../design/Card";
import ScrollToTop from "../functions/ScrollToTop";
import Button from "@mui/material/Button";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";


const text = hyphenated(
  `<p>
  In dieser Phase erfolgt die Bewertung und Analyse der Risiken hinsichtlich ihrer Auswirkungen auf Strategien, Ziele und Kennzahlen. Auch werden bestehende Wechselwirkungen untersucht und die Eintrittswahrscheinlichkeit und Schadensauswirkung auf die Ziele eingeschätzt. Ein tiefes Verständnis der Risiken ist entscheidend, unterstützt durch Risikoszenarien. Verschiedene nützliche Methoden werden hierbei angewandt.
  <br><br>

  Die folgenden Methoden sind in diesem Zusammenhang nützlich.
</p>`,
  { language: de }
);

class Analyse extends Component {
  static name = "Analyse";
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Prozess">
            &lt; Zurück zum Prozess
          </Link>
          <br />
          <br />
          <h1>Risikoanalyse und -bewertung</h1>
          <br></br>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
          <br></br>
          <Card
            title="Quantifizierung von Risiken"
            intro="Objektiv nachvollziehbare Beurteilung von Risiken"
            link="/Quantifizierung"
            icon="analyse"
            active =""
          ></Card>
          <br></br>
          <Card
            title="Risikoportfolio/Risikomatrix"
            intro="Zweidimensionale Matrix zur Risikobewertung"
            link="/Risikomatrix"
            icon="diagramm"
            active=""
          ></Card>
          <br></br>

          <Card
            title="Szenariotechnik"
            link="/Szenariotechnik"
            intro="Szenarien skizzieren für wichtige Einzelrisiken"
            icon="szenario"
            active=""
          ></Card>
          <br></br>
    
          <Card
            title="Fehlermeldesysteme"
            link="/Fehlermeldesysteme"
            active=""
            icon="megafon"
            intro="Prävention von Risiken mittels offener Fehlerkultur"
          ></Card>
          <br></br>
         
          <Card
            title="Wechselwirkungen zwischen Risiken"
            link="/Wechselwirkungen"
            active=""
            icon="wechselwirkung"
            intro="Verschiedene Arten von Wechselwirkungen"
          ></Card>
          <br></br>

        </div>
      </>
    );
  }
}

export default Analyse;
