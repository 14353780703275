import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../functions/ScrollToTop";
import TemplateCard from "../design/TemplateCard";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";

const text = hyphenated(
  `<p>
  Die Wertkettenanalyse strukturiert Unternehmensaktivitäten, um Wettbewerbsvorteile und Risiken zu erkennen. Primäre Aktivitäten tragen zur Wertschöpfung bei, während sekundäre diese unterstützen. Das Business Modell Canvas, ein mögliches Analysemodell, berücksichtigt Kundenbeziehungen, Ressourcen, Partner, Finanzen und externe Einflüsse. Das Originalmodell von Osterwalder und Pigneur wurde ergänzt durch die externen Sphären «Gesellschaft», «Politik/Recht», «Ökologie», «Technologie und Wirtschaft». Beispiele hierfür sind gesellschaftliche Ansprüche und Bedarfe an die Altenpflege, rechtliche Rahmenbedingungen sowie Roboter in der Altenpflege. 
  <br></br>
  </p>`,
  { language: de }
);

class Wertkettenanalyse extends Component {
  static name = "Wertkettenanalyse";
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Identifikation">
            &lt; Zurück zur Risikoidentifikation
            </Link>
            <br></br>
            <br></br>
            <h1>Wertkettenanalyse</h1>
            <div 
          dangerouslySetInnerHTML={{ __html: text }}></div>

<TemplateCard
              style="prozess"
              title="Business Model Canvas"
              description="Exemplarisch vorausgefüllte Excel-Vorlage."
              format="Excel"
              image="Canvas"
              download="Template_Canvas_SGMM_Pflegeheime.xlsx"
            />
          </div>
       </>
    );
  }
}

export default Wertkettenanalyse;
