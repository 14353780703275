import React from "react";
import { Component } from "react";
import TemplateCard from "./design/TemplateCard";

class Templates extends Component {
  render() {
    return (
      <>
        <div className="App">
          <div className="content">
            <h1>Templates</h1>
            <TemplateCard
              style="prozess"
              title="Risikokatalog"
              description="Risikokatalog mit exemplarischen Einträgen und automatischer Erstellung einer Risikomatrix."
              format="Excel"
              image="Risikokatalog"
              download="Template_Risikokatalog.xlsx"
            />
            <TemplateCard
              style="prozess"
              title="Pflegerische Risiken"
              description="Auswahl klinisch-haftungsrechtlicher Risiken in der Pflege."
              format="Excel"
              image="Pflegerische_Risiken"
              download="Template_pflegerische_Risiken.xlsx"
            />
            <TemplateCard
              style="prozess"
              title="Nutzwertanalyse"
              description="Vorlage für eine Nutzwertanalyse inkl. Paarvergleich."
              format="Excel"
              image="Nutzwertanalyse"
              download="Template_Nutzwertanalyse_Paarvergleich.xlsx"
            />
            <TemplateCard
              style="prozess"
              title="Business Model Canvas"
              description="Exemplarisch vorausgefüllte Excel-Vorlage."
              format="Excel"
              image="Canvas"
              download="Template_Canvas_SGMM_Pflegeheime.xlsx"
            />
            <TemplateCard
              style="prozess"
              title="Fehlerbaum"
              description="Vorlage für die Risikoanalyse mittels Fehlerbaum."
              format="PowerPoint"
              image="Fehlerbaum"
              download="Template_Fehlerbaum.pptx"
            />
            <TemplateCard
              style="prozess"
              title="Prozesskette"
              description="Vorlage für die Analyse der Prozesskette."
              format="PowerPoint"
              image="Prozesskette"
              download="Template_Prozesskette_mit_Beispiel.pptx"
            />
            <TemplateCard
              style="prozess"
              title="Risikobericht"
              description="Vorlage für die Präsentation des Risikoberichts."
              format="PowerPoint"
              image="Risikobericht_pptx"
              download="Template_Risikobericht_Detailliert.pptx"
            />
            <TemplateCard
              style="prozess"
              title="Formular unerwünschte Ereignisse"
              description="Ein Muster-Meldeformular für unerwünschte Ereignisse"
              format="Word"
              image="Unerwuenschte_Ereignisse"
              download="Template_Muster_Formular_Unerwuenschte_Ereignisse.docx"
            />
            <TemplateCard
              style="prozess"
              title="Risikobericht"
              description="Vorlage für einen Risikobericht."
              format="Word"
              image="Risikobericht_docx"
              download="Template_Muster_Risikobericht.docx"
            />
            {/*}  <TemplateCard
              style="prozess"
              title="Leitfaden"
              description="Der komplette Leitfaden für ganzheitliches Risikomanagement in Pflegeheimen."
              format="PDF"
              image="Leitfaden"
              download="Leitfaden_Ganzheitliches_Risikomanagement_für_Pflegeeinrichtungen.pdf"
            /> */}
            <br></br>
          </div>
        </div>
      </>
    );
  }
}

export default Templates;
