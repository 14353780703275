import React from "react";
import "./App.css";
import Card from "./components/design/Card";
import Tile from "./components/design/Tile";
import { Link } from "react-router-dom";

function App() {
  return (
    <>
      <div className="Home">
        <div className="App">
          <div className="welcome">
            <div className="text">
              <h1>Integriertes Risikomanagement</h1>
              <p>Für Pflegeeinrichtungen</p>
            </div>
          </div>
          <div className="buttons">
            <Card
              link="Prozess"
              title="Risikomanagement-Prozess"
              active=""
              intro="Hilfreiche Templates und Informationen für ganzheitliches Risikomanagement"
              icon="process_icon"
            ></Card>
            <div className="tiles d-flex align-items-center">
              <Tile
                title="Grundlagen"
                icon="foundation"
                link="GrundlagenRisikomanagement"
              ></Tile>
              <Tile title="Bibliothek" icon="book" link="Bibliothek"></Tile>
              <Tile title="Glossar" icon="lupe" link="Glossar"></Tile>
            </div>
          </div>
          <div className="style_between"></div>
          <div className="news_container">
            <div className="news_style_outwards"></div>
            <div className="news_style_inwards"></div>
            <Link to="Templates" className="a-prozess">
              <div className="news">
                <div className="d-flex align-items-center">
                  <div className="TemplateIcon">
                    <img
                      className="template_icon"
                      alt=""
                      src="/icon/template.svg"
                    />
                  </div>
                  <div className="template_text">
                    <h2>Templates</h2>
                    <p>Direkt zu den Vorlagen zum Download</p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
