import React, { useState } from "react";
import { Component } from "react";

const glossar = [
  {
    key: "COSO",
    text: "Das Committee of Sponsoring Organizations of the Treadway Commissions hat ein Modell entwickelt, das Leitlinien und Rahmenbedingungen für das Risikomanagement beinhaltet.",
  },
  {
    key: "DCGK",
    text: "Der Deutsche Corporate Governance Kodex enthält in Form von Empfehlungen und Anregungen international und national anerkannte Standards gutter und verantwortungsvoller Unternehmensführung.",
  },
  {
    key: "DSGVO",
    text: "Die Datenschutz-Grundverordnung (DSGVO) der Europäischen Union (EU) dient dem Schutz von personenbezogenen Daten, die von privaten Unternehmen und öffentlichen Stellen in der EU verarbeitet werden.",
  },
  {
    key: "EFQM-Modell",
    text: "Das EFQM-Modell ist europäisch ausgerichtet und ermöglicht ebenso ein Zertifikat durch einen Auditor – wie das der EN ISO 9001. Es ist im Gegensatz zur ISO 9001:2015 ein Wettbewerbsmodell, welches nicht auf die Erfüllung von Vorgaben, sondern auf die Selbstverantwortung in der Bewertung abzielt. Zentrales Anliegen des EFQM-Modells ist die stetige Verbesserung mittels Innovation und Lernen in allen Unternehmensteilen und in Zusammenarbeit mit anderen EFQM-Anwendern.",
  },
  {
    key: "Eintrittswahrscheinlichkeit",
    text: "Die Wahrscheinlichkeit für das Eintreten eines bestimmten Ereignisses in einem bestimmten Zeitraum in der Zukunft. Häufig ausgedrückt in % (z.B. 10% = 1 x in 10 Jahren).",
  },
  {
    key: "GRC",
    text: "GRC steht für Governance, Risk und Compliance. Dies sind die wichtigsten Handlungsbereiche der Unternehmensleitung. Die Governance beschreibt dabei die Unternehmensführung durch die Vorgabe von Unternehmenszielen und Richtlinien. Risk hingegen steht für das Risikomanagement. Compliance im Unternehmen dient der Einhaltung von externen und internen Normen, Richtlinien und Gesetzen.",
  },
  {
    key: "IKS",
    text: "IKS bezeichnet das interne Kontrollsystem eines Unternehmens."
  },
  {
    key: "ISO",
    text: "Die International Organization for Standardization (ISO) entwickelt und veröffentlicht internationale Standards."
  },
  {
    key: "ISO 9000 Normenreihe",
    text: "Die ISO 9001:2015 „Qualitätsmanagementsysteme – Anforderungen“ ist die zentrale Norm der Normenreihe ISO 9000 ff. und enthält einen Mindeststandard an Forderungen, nach dem das Qualitätsmanagementsystem des Unternehmens zu gestalten ist. So wird das Unternehmen in die Lage versetzt, die Qualitätsanforderungen der Kunden zu erfüllen."
  },
  {
    key: "PDCA-Zyklus",
    text: "Der PDCA-Zyklus (Plan-Do-Check-Act) ist ein kontinuierlicher Prozess, der den Qualitätsmanager bei der Einführung von qualitätssichernden Massnahmen unterstützt. Die Methode kann ebenfalls zur Entscheidungsfindung und im Projektmanagement eingesetzt werden."
  },
  {
   key: "RMS",
   text: "Das Risikomanagementsystem (RMS) fasst alle Massnahmen und Prozesse zusammen, die der Identifikation, Beurteilung, Steuerung und Überwachung von Risiken dienen." 
  },
  {
    key: "Risiko",
    text: "Ein Risiko stellt eine Abweichung von einem geplanten Zielwert dar. Sie kann sowohl negativ (Gefahr) als auch positiv (Chance) sein. Die Risiko-Definition wird von jedem Unternehmen selbst festgelegt und kann daher von dieser Definition abweichen."
  },
  {
    key: "Risikomatrix",
    text: "Mittels der Risikomatrix wird die Wahrscheinlichkeit des Auftretens eines unerwünschten Ereignisses gegenüber dessen Auswirkung tabellarisch ins Verhältnis gesetzt (Risiko). Es findet eine zweidimensionale Darstellung statt. Dies dient der systematischen Abschätzung und Bewertung der Risiken."
  },
  {
    key: "Schadensausmass",
    text: "Das Schadensausmass beschreibt die Auswirkung des Risikos auf das Unternehmen. Dies kann quantitativ mit einer Zahl (z.B. EBIT, Cash-Flow, Umsatz) oder qualitativ beschrieben werden."
  }
];

const SearchBar = () => {
  const [searchInput, setSearchInput] = useState({
    query: "",
    list: glossar,
  });

  const handleChange = (e) => {
    const results = glossar.filter((glossar) => {
      if (e.target.value === "") return glossar;
      return glossar.key.toUpperCase().match(e.target.value.toUpperCase());
    });
    e.preventDefault();
    setSearchInput({
      query: e.target.value,
      list: results,
    });
  };

  return (
    <div>
      <input
        id="search"
        type="search"
        placeholder="Suchbegriff eingeben"
        onChange={handleChange}
        value={searchInput.query}
      />

      
          {searchInput.list.map((glossar) => (
                    <div className="card-glossar">
                    <h2>{glossar.key}</h2>
                    <p>{glossar.text}</p>
                </div>
          ))}

    </div>
  );
};

class Glossar extends Component {
  static name = "Glossar";
  render() {
    return (
      <>
      <div id="glossar">
        <h1>Glossar</h1>
        <SearchBar></SearchBar>
        </div>
      </>
    );
  }
}

export default Glossar;
