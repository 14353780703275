import React from "react";
import { Link } from "react-router-dom";


function TemplateCard({ style, image, title, link, format, download, description }) {
  let path = "/thumbnails/" + image + ".png";
  let linkpath = "../" + link;
  let styleclass = "template_button " + style;
  let downloadlink = "/templates/" + download;
  let maillink = "mailto:?subject=Template Risikomanagement&body=Hier ist der Downloadlink: <https://ost-risikomanagement.ch" + downloadlink + ">";
  return (
    <>
        <div className="template" id={title}>
          <div className="container container-right d-flex align-items-center">
            <div >
              <img className="thumbnail" alt="Thumbnail für das Template" src={path} />
            </div>
            <div className="col-6 d-flex flex-column align-items-start-template">
              <h3>{title}</h3>
            </div>
          </div>
          <p className="template_description">{description}</p>
          <p className="template_format">Format: {format}</p>
          <div className="container container-right d-flex align-items-center template_buttons">
            <Link to={linkpath} className={styleclass}>Zum Prozess</Link>
            <a className="template_button" href= {downloadlink}>
                Download
            </a>
            <a className="template_button" href= {maillink}>
                Mail
            </a>
          </div>
        </div>
    </>
  );
}

export default TemplateCard;
