import React from "react";
import { Component } from "react";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";


const text = hyphenated(
 ` <h2> Über die App</h2></br>

    <p>Willkommen in unserer App für integriertes Risikomanagement für Alters- und Pflegeheime! <br>
    Hier finden Sie kurze, prägnante Texte zu verschiedenen Themen sowie praktische Vorlagen, die speziell für Praktiker in Pflegeheimen entwickelt wurden. 
    Unser Ziel ist es, Ihnen dabei zu helfen, Risiken effektiv zu identifizieren, bewerten und managen. 
    Durchsuchen Sie die Texte, um einen Überblick zu erhalten und nutzen Sie die Templates, um konkrete Massnahmen in Ihrer täglichen Arbeit umzusetzen.
    <br><br>
    <h3>Risikomanagement-Prozess</h3>
    Den Kern des Risikomanagements bildet der Risikomanagement-Prozess nach ISO-Norm 31000.
    Zu den einzelnen Prozess-Schritten haben wir Informationen zusammengestellt und führen nützliche und bewährte Werzeuge und Methoden ein. 
    Gemeinsam mit der Funk-Stiftung und im Austausch mit Heimleitern, Qualitätsbeauftragten und weiteren Praktikern, die sich mit Risikomanagement beschäftigen, entstanden zudem diverse Templates.
    
    <br><br>
    <h3>Templates</h3>
    Als Templates bezeichnen wir Vorlagen (Excel, Word oder PowerPoint), welche Sie bei Ihren Tätigkeiten im Bereich des Risikomanagements unterstützen sollen. 
    Diese sind entweder zentral über die Startseite der Webapp erreichbar, oder in den einzelnen Kapiteln hinterlegt. 
    <br>
    Da dies eine Webapp ist, können Sie diese auch über Ihren Computer erreichen und die Templates dort herunterladen. 
    Alternativ können Sie die Templates als Mail-Anhang versenden. 

    <br><br>
    <h3>Glossar</h3>
    Das Glossar soll als nützliches Nachschlagewerk für die spezifischen Begriffe dienen,
     die im Kontext des Risikomanagements in Pflegeheimen relevant sind. 
     Nutzen Sie diese Ressource, um Fachterminologie zu entschlüsseln und Ihr Wissen zu vertiefen.
    <br><br>
    <h3>Bibliothek</h3>
    In der Bibliothek finden Sie relevante Literatur. Wo möglich ist diese jeweils verlinkt und kann auf einer externen Seite geöffnet werden. 
    Teilweise handelt es sich um frei verfügbare Literatur, teilweise um kostenpflichtige. 
    <br><br>
`,



{ language: de }
)

class Hilfe extends Component {
  static name = "Hilfe";
  render() {
    return (
      <>
        <div class="App">
          <div class="root">
          <h1>Guide zur Risikomanagement App</h1><br />
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
          </div>
        </div>
      </>
    );
  }
}

export default Hilfe;
