import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import Card from "../design/Card";
import ScrollToTop from "../functions/ScrollToTop";
import Button from "@mui/material/Button";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";

/**
 *  Overview for "verankerung".
 *  One Card for every Subsite.
 *  Subsites need to be linked via index. js => <Route path="Subsite" element={<Subsite />} />
 */

const text = hyphenated(
  `<p>
  Die oberste organisatorische Ebene, wie der Vorstand oder Aufsichts-/Verwaltungsrat, trägt die Verantwortung für das Risikomanagement. Dies umfasst die Überwachung der Leistung, Qualität und Wirtschaftlichkeit. Relevante Bereiche sind Betrieb, Pflege, Betreuung, Alltagsgestaltung und das Beschwerdesystem. Die Geschäftsleitung oder Direktion kontrolliert die operative Ebene und berichtet dem Vorstand (bzw. Aufsichts- oder Verwaltungsrat). In kleineren Einrichtungen übernimmt die Geschäftsleitung oft auch Risikomanagementaufgaben, während grössere Organisationen spezielle Risikomanager haben. Das 3 Lines of Defense Modell ordnet Rollen und Verantwortlichkeiten im internen Kontrollsystem zu, darunter Risikoeigentümer, operative Kontrollen und unabhängige Organisationseinheiten wie die interne Revision. Dies fördert die regelmässige Überprüfung und Effektivität des Risikomanagements.
  </p>`,
  { language: de }
);

class Verankerung extends Component {
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Prozess">
            &lt; Zurück zum Prozess
          </Link>
          <br />
          <br />
          <h1>Verankerung</h1>
          <br></br>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
          <br></br>
        </div>
      </>
    );
  }
}

export default Verankerung;
