import React from "react";
import { hyphenated } from "hyphenated";
import de from "hyphenated-de";

function Book({ number, citation, linktext, url }) {
    citation = hyphenated(citation, { language: de });
  
    return (
      <>
        <a className="a-prozess" href={url} target="_blank" rel="noopener noreferrer">
          <div className="book" id={number}>
            <div className="container container-right d-flex align-items-center">
              <div className="col-6 d-flex flex-column align-items-start-book">
                <p>{citation}</p>
                <p className="intro-book">{linktext} ↗</p>
              </div>
            </div>
          </div>
        </a>
      </>
    );
  }

  export default Book;