import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../functions/ScrollToTop";
import TemplateCard from "../design/TemplateCard";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";

const text = hyphenated(
  `<p>
  Die Nutzung eines Risikokatalogs zur Identifizierung von Gefahren in Pflegeheimen bietet klare Vorteile. Risiken sind in verschiedene Kategorien unterteilt, darunter strategische, externe, finanzielle, IT-, personelle, Bewohner- und Angehörigen-, Prozess-, Infrastruktur- und Reputationsrisiken. Es kann jedoch zu Überschneidungen kommen. Branchenspezifische Risikokataloge erleichtern die Identifizierung. Die Analyse von Risikoverkettungen ist entscheidend. Die Frequenz der Risikobewertung variiert je nach Art, wobei strategische Risiken jährlich und operative mehrmals im Jahr überprüft werden. Risikokataloge sind nützliche Werkzeuge, jedoch ersetzen sie nicht die individuelle Risikoanalyse jedes spezifischen Falls.
  <br></br>
</p>`,
  { language: de }
);

class Risikokatalog extends Component {
  static name = "Risikokatalog";
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Identifikation">
            &lt; Zurück zur Risikoidentifikation
          </Link>
          <br></br>
          <br></br>
          <h1>Risikokatalog</h1>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
          <TemplateCard
            style="prozess"
            title="Risikokatalog"
            description="Risikokatalog mit exemplarischen Einträgen und automatischer Erstellung einer Risikomatrix."
            format="Excel"
            image="Risikokatalog"
            download="Template_Risikokatalog.xlsx"
          />
          <TemplateCard
            style="prozess"
            title="Pflegerische Risiken"
            description="Auswahl klinisch-haftungsrechtlicher Risiken in der Pflege."
            format="Excel"
            image="Pflegerische_Risiken"
            download="Template_pflegerische_Risiken.xlsx"
          />
        </div>
      </>
    );
  }
}

export default Risikokatalog;
