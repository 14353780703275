import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../functions/ScrollToTop";
import TemplateCard from "../design/TemplateCard";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";

const text = hyphenated(
  `<p>
  Risikoportfolios sind gebräuchliche Tools zur Bewertung und Darstellung von Risiken. Sie verwenden eine zweidimensionale Matrix, die Eintrittswahrscheinlichkeit und Schadensausmass auf den Achsen abbildet. Optional kann eine dritte Dimension hinzugefügt werden, dargestellt beispielsweise durch die Grösse der Kreise. Eine Risikoschwelle kann eingeführt werden, um die Dringlichkeit risikomindernder Massnahmen zu verdeutlichen. Die Position dieser Schwelle spiegelt die Risikotoleranz der Organisation wider. Grün, gelb und rot werden verwendet, um niedrige, mittlere und hohe Risiken zu kennzeichnen. Diese Portfolios sind hilfreich für die Darstellung in  Risikoberichten. Der Nachteil besteht allerdings darin, dass Risiken fälschlicherweise in den Toleranzbereich fallen können, wenn die Eintrittswahrscheinlichkeiten falsch geschätzt werden. Selten auftretende Risiken mit hohem Schadensausmass sind besonders problematisch, da sie oft unterschätzt werden. 
  </p>`,
  { language: de }
);

class Risikomatrix extends Component {
  static name = "Risikomatrix";
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Analyse">
            &lt; Zurück zur Risikoanalyse und -bewertung
          </Link>
          <br></br>
          <br></br>
          <h1>Risikoportfolio / Risikomatrix</h1>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
          <TemplateCard
              style="prozess"
              title="Risikokatalog"
              description="Risikokatalog mit exemplarischen Einträgen und automatischer Erstellung einer Risikomatrix."
              format="Excel"
              image="Risikokatalog"
              download="Template_Risikokatalog.xlsx"
            />
        </div>
      </>
    );
  }
}

export default Risikomatrix;
