import React from "react";
import { Component } from "react";
import ScrollToTop from "../functions/ScrollToTop";
import { styled } from "@mui/system";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  backgroundColor: "transparent",
  marginLeft: "30px",
  paddingRight: "30px",
  display: "block",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "1.2rem", color: "darkgrey" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  marginLeft: "-45px",
  "& .MuiAccordionSummary-content": {
    marginLeft: "5px",
    Typography: {
      fontSize: "1.0rem",
    },
  },
}));


const einleitung = hyphenated(
  `Die zunehmende Komplexität und Dynamik in Organisationen und ihrem Umfeld erhöhen das Risiko. Das Risikomanagement hat daher an Bedeutung gewonnen, besonders im Gesundheitswesen. Demografische Entwicklungen wie steigende Lebenserwartung und Multimorbidität, technologischer Fortschritt und hohe Erwartungen der Leistungsnehmenden setzen Druck auf. Pflegeeinrichtungen in der DACH-Region unterliegen Pflegestufen oder -graden mit festen Tagespauschalen. Betriebswirtschaftliche Führung ist entscheidend und erfordert ein Risiko- und Chancenkalkül, das strategische Ziele und unternehmerische Entscheidungen berücksichtigt. Risikomanagement fokussiert auf 10-30 Schlüsselrisiken, während der Rest periodisch überprüft wird. Die Gesundheitsbranche hat jedoch wenig Literatur dazu, und das Thema erfordert eine integrierte Betrachtung von pflegerischen, medizinischen und betriebswirtschaftlichen Aspekten, einschliesslich Sicherheit und Verwaltungsrisiken.<br /> <br />`,
  { language: de }
);

const allgemeineGrundlagenRiskomanagement = hyphenated(
  `Risiko in den Wirtschaftswissenschaften bedeutet die Möglichkeit negativer Abweichungen von Zielen, verursacht durch Unsicherheiten. Positive Abweichungen hingegen werden als Chancen betrachtet. Risikoeinschätzungen basieren auf Wahrscheinlichkeitsberechnungen und können alle Unternehmensziele beeinflussen.<br /><br />
  Die ISO-Norm 31000 definiert Risiko als "Auswirkungen von Unsicherheiten auf Ziele". <br /><br />
  Risiken lassen sich nach Typ, Dauer, Beginn, Reaktionszeit und Auswirkungen unterscheiden. Wichtige Risikokategorien umfassen Markt-, Management-, operative, finanzielle, IT- und ökologische Risiken. Unternehmen müssen sowohl ihr internes als auch das externe Umfeld berücksichtigen, wobei interne Bereiche durch Konzepte wie eine Balanced Scorecard abgedeckt werden kann. <br /><br />
  Risikomanagement ist ein kontinuierlicher Prozess, der von Führungskräften bei der Strategiedefinition und -umsetzung angewendet wird. Es zielt darauf ab, Unsicherheiten zu erkennen und gezielt zu steuern. Die Hauptaufgaben dabei sind die Förderung einer Risikokultur, kontinuierliche Berichterstattung und die Weiterentwicklung des Prozesses.<br /><br />
  Risikostrategien, die die Unternehmenshaltung gegenüber Risiken festlegen, sind ein wichtiger Teil der Unternehmensstrategie und umfassen Akzeptanz, Vermeidung oder Übertragung von Risiken.<br /><br />
  `,
  { language: de }
);

const risikounfehlerkultur = hyphenated(
  ` Risiko- und Fehlerkultur im Gesundheitswesen sind entscheidend. Risiken sollen frühzeitig erkannt und Fehler vermieden werden. Eine gute Fehlerkultur akzeptiert Fehler, fördert das Lernen daraus und bietet Werkzeuge für den Umgang mit ihnen.<br /><br />
  Die Fehlerkultur setzt sich aus Normen/Werten, Kompetenzen und Instrumenten zusammen. Mitarbeitende müssen die Fähigkeiten haben, Fehler zu bewältigen, und die Organisation muss entsprechende Werkzeuge bereitstellen.<br /><br />
  Studien zeigen, dass Mitarbeitende oft zögern, Fehler zu melden, aufgrund von organisatorischen Barrieren und Ängsten. Eine positive Fehlerkultur hingegen ermutigt zur Meldung und trägt dadurch zur Sicherheit bei.<br /><br />
  Eine gelebte und positive Fehlerkultur stärkt das Vertrauen in die Führung, ermöglicht frühzeitige Risikoerkennung und fördert Innovation. Führungskräfte spielen hierbei eine wichtige Rolle, indem sie ehrlich sind, klare Erwartungen setzen und Vertrauen in die Mitarbeitenden zeigen.
  <br /><br />
  `,
  { language: de }
);

const risikomanagementInAltersUndPflegeheimen = hyphenated(
  `Risikomanagement im Gesundheitswesen ist grundsätzlich verbreitet, aber die Umsetzung bleibt anspruchsvoll. Es umfasst Bereiche wie Sturzprävention, Dekubitusprophylaxe, Hygiene, Ernährung und andere Bereiche, doch oft fehlt eine wissenschaftlich fundierte Basis.<br /><br />
  Strukturiertes Risikomanagement verbessert die Sicherheit der Bewohnenden in Pflegeeinrichtungen. Es betrifft Bewohner und Bewohnerinnen, Angehörige, Personal und die Organisation selbst. Komplexe Gesundheitsprobleme, insbesondere Demenz, erfordern spezielle Aufmerksamkeit.<br /><br />
  Risiken im Personal- und Organisationsbereich beinhalten den Fachkräftemangel, Arbeitsbelastungen und psychosoziale Belastungen. Die Übersichtlichkeit der Aufgaben und der persönliche Kontakt zu Seniorinnen und Senioren sind wichtig für die Zufriedenheit der Mitarbeitenden.<br /><br />
  Die Arbeit in der Altenpflege birgt gesundheitliche Risiken wie Muskel-Skelett-Belastungen, Hautprobleme und Infektionsgefahren. Schichtarbeit, Trauer und Gewalterfahrungen sind zusätzliche Belastungen.<br /><br />
  Unerwünschte Arzneimittelereignisse sind ein Problem, das häufig auf Ablenkungen und Probleme mit Richtlinien zurückzuführen ist. In der Hauswirtschaft und der Küche sind Kontrollen üblich, um Risiken zu minimieren.<br /><br />
  Weitere Bereiche des Risikomanagements in Pflegeeinrichtungen sind Arbeitssicherheit, Datenschutz und Datensicherheit. <br /><br />
  `,
  { language: de }
);

export class GrundlagenRisikomanagement extends Component {
  static name = "Grundlagen Risikomanagement";
  render() {
    return (
      <>
        <ScrollToTop /> 
        <div className="App">
          <div className="content">
            <h1>Grundlagen Risikomanagement</h1><br />
            <Accordion>
              <AccordionSummary>
                <h2>Einleitung</h2>
              </AccordionSummary>
              <div
                className="textContainer"
                dangerouslySetInnerHTML={{ __html: einleitung }}
              ></div>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <h2>Allgemeine Grundlagen Risikomanagement</h2>
              </AccordionSummary>
              <div
                className="textContainer"
                dangerouslySetInnerHTML={{
                  __html: allgemeineGrundlagenRiskomanagement,
                }}
              ></div>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <h2>Risiko- und Fehlerkultur</h2>
              </AccordionSummary>
              <div
                className="textContainer"
                dangerouslySetInnerHTML={{
                  __html: risikounfehlerkultur,
                }}
              ></div>
            </Accordion>

            <Accordion>
              <AccordionSummary>
                <h2>Risikomanagement in Alters- und Pflegeheimen</h2>
              </AccordionSummary>
              <div
                className="textContainer"
                dangerouslySetInnerHTML={{
                  __html: risikomanagementInAltersUndPflegeheimen,
                }}
              ></div>
            </Accordion>
          </div>
        </div>
      </>
    );
  }
}
