import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "./functions/ScrollToTop";


function ProcessStep({ icon, title, description, intro, link, number, style }) {
  let path = "/icon/" + icon + ".svg";
  let icon_path = "/icon/nummer-" + number + ".svg";
  let styleClass ="row-right card_custom" + style;
  return (
    <>
    <ScrollToTop />
    <Link className="a-prozess" to={link}>
    <div id={title} className="wrapper d-flex align-items-center">
      <div className="container container-right d-flex align-items-center">
        <div className="d-flex align-items-center number-container">
          <img className="number" alt="" src={icon_path} id={number} />
        </div>
        <div className={styleClass}>
          <div className="row row-process">
            <div className="col-4 d-flex flex-wrap align-items-center icon-div">
              <img className="icon" alt="" src={path} id={icon} />
            </div>
            <div className="col-5 d-flex flex-column align-items-start">
              <h2>{title}</h2>
              <p className="intro">{intro}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Link>
    </>
  );
}

class Prozess extends Component {
  render() {
    return (
      <>
        <div id="main">
          <h1>Integriertes Risikomanagement</h1>
          <p id="intro">
            Den Kern des Risikomanagements bildet der Risikomanagement-Prozess
            nach ISO-Norm 31000.
          </p>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 251.91 180"
            id="process-svg"
          >
            <g id="Text">
            <Link to="/Überwachung">  
              <g>
                <rect
                  className="cls-1"
                  x="216.26"
                  width="35.65"
                  height="180"
                  rx="15.64"
                  ry="15.64"
                />
                <g>
                  <path
                    className="cls-2"
                    d="M233.3,16.6c-2.16,0-3.24-.98-3.24-2.93s1.04-2.8,3.12-2.8h4.71v.9h-4.65c-1.58,0-2.37,.67-2.37,2s.76,1.93,2.29,1.93h4.73v.9h-4.59Zm5.3-3.9c0-.15,.05-.28,.16-.38,.11-.11,.23-.16,.37-.16s.28,.05,.38,.15c.1,.1,.15,.23,.15,.37s-.05,.27-.15,.38c-.1,.11-.23,.16-.37,.16s-.27-.05-.38-.16-.16-.23-.16-.37Zm0,2.06c0-.14,.05-.27,.15-.37,.1-.1,.23-.16,.38-.16s.28,.05,.38,.15c.1,.1,.15,.22,.15,.37s-.05,.28-.15,.38c-.1,.1-.23,.16-.37,.16s-.27-.05-.38-.15c-.1-.1-.16-.23-.16-.38Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.98,19.31v-.02h-.79v-.88h8.14v.88h-3.61v.02c.73,.43,1.1,1.07,1.1,1.9,0,.71-.25,1.26-.74,1.66s-1.15,.6-1.98,.6c-.92,0-1.66-.22-2.21-.67-.55-.45-.83-1.06-.83-1.84,0-.73,.31-1.28,.92-1.65Zm2.22-.02h-.77c-.45,0-.84,.15-1.16,.44-.32,.29-.48,.67-.48,1.12,0,.53,.2,.95,.61,1.25s.98,.45,1.7,.45c.61,0,1.09-.14,1.44-.42,.35-.28,.52-.67,.52-1.15,0-.51-.18-.92-.53-1.23-.36-.31-.8-.47-1.34-.47Z"
                  />
                  <path
                    className="cls-2"
                    d="M232.72,29.29v-3.88c-.61,.01-1.08,.18-1.42,.49-.33,.32-.5,.75-.5,1.3,0,.62,.2,1.19,.61,1.71h-.83c-.35-.48-.53-1.12-.53-1.92s.25-1.39,.75-1.83c.5-.44,1.2-.67,2.11-.67,.86,0,1.55,.24,2.09,.73s.81,1.09,.81,1.81-.23,1.28-.7,1.67c-.47,.39-1.11,.59-1.94,.59h-.46Zm.75-.9c.51,0,.9-.13,1.19-.37,.28-.24,.42-.58,.42-1.01s-.15-.77-.45-1.06c-.3-.29-.69-.47-1.17-.54v2.97Z"
                  />
                  <path
                    className="cls-2"
                    d="M234.8,33.49c.12-.15,.18-.38,.18-.67,0-.38-.18-.69-.53-.94-.35-.25-.84-.38-1.45-.38h-2.8v-.88h5.5v.88h-1.13v.02c.39,.12,.69,.32,.9,.58s.33,.55,.33,.86c0,.23-.02,.4-.08,.53h-.91Z"
                  />
                  <path
                    className="cls-2"
                    d="M235.69,41.82l-5.5-1.65v-.91l3.94-1.13c.15-.04,.32-.07,.51-.09v-.02c-.13-.01-.29-.05-.5-.11l-3.95-1.23v-.88l5.5-1.67v.92l-4.14,1.14c-.13,.04-.29,.06-.49,.08v.04c.16,.01,.33,.04,.5,.1l4.12,1.27v.81l-4.15,1.14c-.13,.04-.3,.06-.49,.08v.04c.14,0,.3,.04,.49,.09l4.15,1.12v.87Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.19,46.77v-.88h.86v-.02c-.66-.38-.99-.95-.99-1.69,0-.55,.14-.98,.44-1.29,.29-.31,.67-.46,1.15-.46,1.03,0,1.62,.61,1.79,1.82l.23,1.65c.93,0,1.4-.38,1.4-1.13,0-.66-.23-1.26-.68-1.79h.9c.34,.54,.52,1.16,.52,1.87,0,1.29-.68,1.94-2.05,1.94h-3.58Zm2.78-.88l-.18-1.33c-.06-.41-.16-.72-.3-.92-.14-.21-.4-.31-.77-.31-.27,0-.49,.1-.66,.29s-.25,.45-.25,.77c0,.44,.15,.8,.46,1.08,.31,.29,.69,.43,1.16,.43h.55Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.44,52.18c-.25-.42-.38-.92-.38-1.5,0-.78,.25-1.42,.77-1.9,.51-.48,1.17-.72,1.98-.72,.91,0,1.63,.26,2.18,.78,.55,.52,.82,1.21,.82,2.08,0,.48-.09,.91-.27,1.28h-.9c.29-.41,.43-.84,.43-1.31,0-.56-.2-1.02-.6-1.38-.4-.36-.93-.54-1.59-.54s-1.15,.17-1.53,.51c-.37,.34-.56,.79-.56,1.36,0,.48,.16,.93,.48,1.35h-.84Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.19,58.08v-.88h3.17c1.15,0,1.72-.43,1.72-1.28,0-.43-.17-.79-.5-1.08-.33-.29-.76-.44-1.28-.44h-3.12v-.88h8.14v.88h-3.56v.02c.69,.42,1.04,1.02,1.04,1.8,0,1.24-.75,1.86-2.24,1.86h-3.39Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.19,64.19v-.88h.87v-.02c-.67-.37-1-.93-1-1.7,0-1.31,.78-1.97,2.34-1.97h3.29v.88h-3.15c-1.16,0-1.74,.44-1.74,1.33,0,.43,.16,.78,.48,1.06,.32,.28,.73,.42,1.24,.42h3.17v.88h-5.5Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.19,70.53v-.88h3.14c1.17,0,1.75-.43,1.75-1.28,0-.44-.17-.8-.5-1.09-.33-.29-.75-.43-1.25-.43h-3.14v-.88h5.5v.88h-.91v.02c.69,.42,1.04,1.02,1.04,1.8,0,.6-.19,1.06-.58,1.38-.39,.32-.95,.48-1.68,.48h-3.36Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.63,76.89c-2.02,0-3.03-.97-3.03-2.9,0-.68,.13-1.27,.39-1.78h.88c-.34,.62-.52,1.21-.52,1.77,0,1.35,.72,2.03,2.16,2.03h.6v-.02c-.7-.42-1.05-1.05-1.05-1.89,0-.68,.24-1.23,.73-1.65,.49-.42,1.14-.63,1.97-.63,.94,0,1.68,.22,2.23,.67s.83,1.06,.83,1.85c0,.74-.3,1.29-.89,1.65v.02h.76v.88h-5.06Zm2.05-.88h.81c.44,0,.81-.15,1.12-.44,.31-.29,.47-.66,.47-1.1,0-.54-.2-.97-.59-1.28s-.95-.46-1.66-.46c-.61,0-1.1,.15-1.47,.44-.37,.29-.55,.69-.55,1.17s.17,.9,.53,1.21,.8,.46,1.35,.46Z"
                  />
                  <path
                    className="cls-2"
                    d="M233.04,87.08v-2.17h-2.17v-.71h2.17v-2.16h.72v2.16h2.16v.71h-2.16v2.17h-.72Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.19,97.7v-1.26l3.52-2.98c.13-.11,.22-.18,.27-.2v-.02h-3.79v-.9h7.7v.9h-3.62v.02c.08,.05,.17,.12,.26,.2l3.36,2.88v1.12l-3.7-3.3-4.01,3.53Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.06,100.75c0-.81,.26-1.46,.77-1.95,.51-.49,1.2-.73,2.04-.73,.92,0,1.65,.25,2.16,.76,.52,.5,.78,1.19,.78,2.05s-.25,1.46-.76,1.92c-.5,.46-1.21,.69-2.1,.69s-1.58-.25-2.11-.74-.79-1.16-.79-2Zm5.02,.06c0-.57-.19-1.01-.58-1.34s-.92-.49-1.59-.49-1.17,.17-1.54,.5c-.38,.33-.56,.78-.56,1.34s.18,1.01,.55,1.31c.37,.31,.89,.46,1.57,.46s1.22-.15,1.59-.46c.37-.31,.56-.74,.56-1.31Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.19,112.71v-.88h3.16c.61,0,1.05-.09,1.32-.28,.27-.19,.41-.5,.41-.95,0-.38-.17-.7-.52-.96-.34-.26-.75-.4-1.24-.4h-3.14v-.88h3.27c1.08,0,1.62-.42,1.62-1.25,0-.39-.16-.71-.49-.96-.32-.25-.75-.38-1.26-.38h-3.14v-.88h5.5v.88h-.87v.02c.67,.39,1,.96,1,1.71,0,.38-.1,.7-.31,.98-.21,.28-.48,.47-.82,.58,.76,.41,1.14,1.02,1.14,1.83,0,1.21-.75,1.82-2.24,1.82h-3.39Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.19,122.18v-.88h3.16c.61,0,1.05-.09,1.32-.28,.27-.19,.41-.5,.41-.95,0-.38-.17-.7-.52-.96-.34-.26-.75-.4-1.24-.4h-3.14v-.88h3.27c1.08,0,1.62-.42,1.62-1.25,0-.39-.16-.71-.49-.96-.32-.25-.75-.38-1.26-.38h-3.14v-.88h5.5v.88h-.87v.02c.67,.39,1,.96,1,1.71,0,.38-.1,.7-.31,.98-.21,.28-.48,.47-.82,.58,.76,.41,1.14,1.02,1.14,1.83,0,1.21-.75,1.82-2.24,1.82h-3.39Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.19,128.3v-.88h.87v-.02c-.67-.37-1-.93-1-1.7,0-1.31,.78-1.97,2.34-1.97h3.29v.88h-3.15c-1.16,0-1.74,.44-1.74,1.33,0,.43,.16,.78,.48,1.06,.32,.28,.73,.42,1.24,.42h3.17v.88h-5.5Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.19,134.64v-.88h3.14c1.17,0,1.75-.43,1.75-1.28,0-.44-.17-.8-.5-1.09-.33-.29-.75-.43-1.25-.43h-3.14v-.88h5.5v.88h-.91v.02c.69,.42,1.04,1.02,1.04,1.8,0,.6-.19,1.06-.58,1.38-.39,.32-.95,.48-1.68,.48h-3.36Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.19,137.18v-.88h5.5v.88h-5.5Zm6.9-.43c0-.16,.05-.29,.16-.4,.11-.11,.24-.17,.41-.17s.3,.06,.41,.17c.11,.11,.16,.25,.16,.4s-.05,.3-.16,.41c-.11,.11-.25,.17-.41,.17s-.29-.06-.4-.17c-.11-.11-.17-.25-.17-.41Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.19,143.53v-1.24l2.64-2.43v-.02h-2.64v-.88h8.14v.88h-5.16v.02l2.52,2.31v1.15l-2.65-2.55-2.85,2.75Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.19,148.37v-.88h.86v-.02c-.66-.38-.99-.95-.99-1.69,0-.55,.14-.98,.44-1.29,.29-.31,.67-.46,1.15-.46,1.03,0,1.62,.61,1.79,1.82l.23,1.65c.93,0,1.4-.38,1.4-1.13,0-.66-.23-1.26-.68-1.79h.9c.34,.54,.52,1.16,.52,1.87,0,1.29-.68,1.94-2.05,1.94h-3.58Zm2.78-.88l-.18-1.33c-.06-.41-.16-.72-.3-.92-.14-.21-.4-.31-.77-.31-.27,0-.49,.1-.66,.29s-.25,.45-.25,.77c0,.44,.15,.8,.46,1.08,.31,.29,.69,.43,1.16,.43h.55Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.24,152.58c-.11-.21-.17-.48-.17-.82,0-.96,.54-1.45,1.61-1.45h3.25v-.95h.75v.95h1.34l.28,.88h-1.63v1.39h-.75v-1.39h-3.1c-.37,0-.63,.06-.79,.19s-.24,.33-.24,.62c0,.22,.06,.41,.18,.58h-.75Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.19,154.64v-.88h5.5v.88h-5.5Zm6.9-.43c0-.16,.05-.29,.16-.4,.11-.11,.24-.17,.41-.17s.3,.06,.41,.17c.11,.11,.16,.25,.16,.4s-.05,.3-.16,.41c-.11,.11-.25,.17-.41,.17s-.29-.06-.4-.17c-.11-.11-.17-.25-.17-.41Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.06,158.72c0-.81,.26-1.46,.77-1.95,.51-.49,1.2-.73,2.04-.73,.92,0,1.65,.25,2.16,.76,.52,.5,.78,1.19,.78,2.05s-.25,1.46-.76,1.92c-.5,.46-1.21,.69-2.1,.69s-1.58-.25-2.11-.74-.79-1.16-.79-2Zm5.02,.06c0-.57-.19-1.01-.58-1.34s-.92-.49-1.59-.49-1.17,.17-1.54,.5c-.38,.33-.56,.78-.56,1.34s.18,1.01,.55,1.31c.37,.31,.89,.46,1.57,.46s1.22-.15,1.59-.46c.37-.31,.56-.74,.56-1.31Z"
                  />
                  <path
                    className="cls-2"
                    d="M230.19,167.43v-.88h3.14c1.17,0,1.75-.43,1.75-1.28,0-.44-.17-.8-.5-1.09-.33-.29-.75-.43-1.25-.43h-3.14v-.88h5.5v.88h-.91v.02c.69,.42,1.04,1.02,1.04,1.8,0,.6-.19,1.06-.58,1.38-.39,.32-.95,.48-1.68,.48h-3.36Z"
                  />
                </g>
                <path
                  className="cls-1"
                  d="M213.53,16.12l5.35,3.09c.54,.31,1.21-.08,1.21-.7v-6.18c0-.62-.67-1.01-1.21-.7l-5.35,3.09c-.54,.31-.54,1.08,0,1.39Z"
                />
                <path
                  className="cls-1"
                  d="M213.27,164.69l5.35,3.09c.54,.31,1.21-.08,1.21-.7v-6.18c0-.62-.67-1.01-1.21-.7l-5.35,3.09c-.54,.31-.54,1.08,0,1.39Z"
                />
                <path
                  className="cls-1"
                  d="M213.55,128.43l5.35,3.09c.54,.31,1.21-.08,1.21-.7v-6.18c0-.62-.67-1.01-1.21-.7l-5.35,3.09c-.54,.31-.54,1.08,0,1.39Z"
                />
                <path
                  className="cls-1"
                  d="M213.21,90.99l5.35,3.09c.54,.31,1.21-.08,1.21-.7v-6.18c0-.62-.67-1.01-1.21-.7l-5.35,3.09c-.54,.31-.54,1.08,0,1.39Z"
                />
                <path
                  className="cls-1"
                  d="M213.23,53.56l5.35,3.09c.54,.31,1.21-.08,1.21-.7v-6.18c0-.62-.67-1.01-1.21-.7l-5.35,3.09c-.54,.31-.54,1.08,0,1.39Z"
                />
              </g>
              </Link>
            </g>
            <g id="Numbers">
              
               <Link to="/Verankerung">  
               <g>
                <path
                  className="cls-1"
                  d="M211.01,14.73l-5.35-3.09c-.54-.31-1.21,.08-1.21,.7v6.18c0,.62,.67,1.01,1.21,.7l5.35-3.09c.54-.31,.54-1.08,0-1.39Z"
                />
                <g>
                  <rect
                    className="cls-1"
                    x="0"
                    y="0"
                    width="208.17"
                    height="31.3"
                    rx="15.65"
                    ry="15.65"
                  />
                  <g>
                    <path
                      className="cls-2"
                      d="M33.62,18.42c-1.19,0-2.15-.39-2.86-1.18s-1.08-1.81-1.08-3.06c0-1.35,.37-2.43,1.1-3.23s1.72-1.21,2.98-1.21c1.16,0,2.09,.39,2.8,1.17s1.06,1.8,1.06,3.06c0,1.37-.36,2.46-1.09,3.25s-1.7,1.2-2.92,1.2Zm.07-7.79c-.88,0-1.6,.32-2.15,.96s-.83,1.47-.83,2.51,.27,1.87,.81,2.5,1.24,.95,2.1,.95c.92,0,1.65-.3,2.18-.9s.8-1.44,.8-2.53-.26-1.97-.77-2.57-1.23-.91-2.13-.91Z"
                    />
                    <path
                      className="cls-2"
                      d="M42.28,13.25c-.17-.13-.41-.19-.73-.19-.41,0-.75,.19-1.03,.58s-.41,.91-.41,1.58v3.06h-.96v-6h.96v1.24h.02c.14-.42,.35-.75,.63-.99s.6-.35,.94-.35c.25,0,.44,.03,.57,.08v1Z"
                    />
                    <path
                      className="cls-2"
                      d="M48.28,17.8c0,2.2-1.05,3.3-3.16,3.3-.74,0-1.39-.14-1.95-.42v-.96c.68,.38,1.32,.56,1.93,.56,1.48,0,2.21-.79,2.21-2.36v-.66h-.02c-.46,.77-1.14,1.15-2.06,1.15-.75,0-1.35-.27-1.8-.8s-.68-1.25-.68-2.15c0-1.02,.25-1.83,.74-2.43s1.16-.9,2.01-.9,1.41,.32,1.8,.97h.02v-.83h.96v5.52Zm-.96-2.23v-.88c0-.48-.16-.88-.48-1.22s-.72-.51-1.2-.51c-.59,0-1.06,.22-1.39,.65s-.5,1.04-.5,1.81c0,.67,.16,1.2,.48,1.6s.75,.6,1.28,.6,.98-.19,1.32-.57,.51-.87,.51-1.47Z"
                    />
                    <path
                      className="cls-2"
                      d="M54.52,18.28h-.96v-.94h-.02c-.42,.72-1.03,1.08-1.85,1.08-.6,0-1.07-.16-1.4-.47s-.51-.74-.51-1.26c0-1.12,.66-1.77,1.98-1.96l1.8-.25c0-1.02-.41-1.53-1.24-1.53-.72,0-1.38,.25-1.96,.74v-.98c.59-.38,1.27-.56,2.04-.56,1.41,0,2.12,.75,2.12,2.24v3.9Zm-.96-3.04l-1.45,.2c-.45,.06-.78,.17-1.01,.33s-.34,.44-.34,.84c0,.29,.1,.53,.31,.72s.49,.28,.83,.28c.48,0,.87-.17,1.18-.5s.47-.76,.47-1.27v-.6Z"
                    />
                    <path
                      className="cls-2"
                      d="M61.31,18.28h-.96v-3.42c0-1.27-.46-1.91-1.39-1.91-.48,0-.88,.18-1.19,.54s-.47,.82-.47,1.37v3.42h-.96v-6h.96v1h.02c.45-.76,1.11-1.14,1.97-1.14,.66,0,1.16,.21,1.51,.64s.52,1.04,.52,1.84v3.67Z"
                    />
                    <path
                      className="cls-2"
                      d="M63.62,10.76c-.17,0-.32-.06-.44-.18s-.18-.27-.18-.45,.06-.33,.18-.45,.27-.18,.44-.18,.33,.06,.45,.18,.18,.27,.18,.45-.06,.32-.18,.44-.27,.18-.45,.18Zm.47,7.52h-.96v-6h.96v6Z"
                    />
                    <path
                      className="cls-2"
                      d="M65.67,18.06v-1.03c.52,.39,1.1,.58,1.73,.58,.84,0,1.27-.28,1.27-.84,0-.16-.04-.3-.11-.41s-.17-.21-.29-.3-.27-.16-.43-.23-.34-.14-.54-.21c-.27-.11-.5-.21-.7-.32s-.37-.23-.5-.36-.24-.29-.3-.46-.1-.37-.1-.6c0-.28,.06-.53,.19-.75s.3-.4,.52-.54,.46-.26,.74-.33,.56-.11,.85-.11c.52,0,.98,.09,1.39,.27v.97c-.44-.29-.95-.43-1.52-.43-.18,0-.34,.02-.49,.06s-.27,.1-.37,.17-.18,.16-.24,.27-.08,.22-.08,.34c0,.16,.03,.29,.08,.39s.14,.2,.25,.28,.24,.16,.4,.22,.33,.14,.53,.22c.27,.1,.5,.21,.71,.31s.39,.23,.54,.36,.26,.29,.34,.47,.12,.38,.12,.63c0,.3-.07,.55-.2,.77s-.31,.4-.52,.54-.47,.25-.76,.32-.58,.11-.9,.11c-.62,0-1.15-.12-1.61-.36Z"
                    />
                    <path
                      className="cls-2"
                      d="M75.42,18.28h-.96v-.94h-.02c-.42,.72-1.03,1.08-1.85,1.08-.6,0-1.07-.16-1.4-.47s-.51-.74-.51-1.26c0-1.12,.66-1.77,1.98-1.96l1.8-.25c0-1.02-.41-1.53-1.24-1.53-.72,0-1.38,.25-1.96,.74v-.98c.59-.38,1.27-.56,2.04-.56,1.41,0,2.12,.75,2.12,2.24v3.9Zm-.96-3.04l-1.45,.2c-.45,.06-.78,.17-1.01,.33s-.34,.44-.34,.84c0,.29,.1,.53,.31,.72s.49,.28,.83,.28c.48,0,.87-.17,1.18-.5s.47-.76,.47-1.27v-.6Z"
                    />
                    <path
                      className="cls-2"
                      d="M80.01,18.22c-.23,.12-.53,.19-.9,.19-1.05,0-1.58-.59-1.58-1.76v-3.55h-1.03v-.82h1.03v-1.46l.96-.31v1.78h1.51v.82h-1.51v3.38c0,.4,.07,.69,.21,.86s.36,.26,.68,.26c.24,0,.45-.07,.63-.2v.82Z"
                    />
                    <path
                      className="cls-2"
                      d="M83.71,18.42c-.89,0-1.59-.28-2.12-.84s-.79-1.3-.79-2.23c0-1.01,.28-1.79,.83-2.36s1.29-.85,2.23-.85,1.59,.28,2.09,.83,.75,1.31,.75,2.29-.27,1.72-.81,2.3-1.27,.86-2.18,.86Zm.07-5.47c-.62,0-1.11,.21-1.46,.63s-.54,1-.54,1.74,.18,1.27,.54,1.68,.85,.62,1.46,.62,1.1-.2,1.43-.6,.5-.97,.5-1.72-.17-1.33-.5-1.73-.81-.61-1.43-.61Z"
                    />
                    <path
                      className="cls-2"
                      d="M91.36,13.25c-.17-.13-.41-.19-.73-.19-.41,0-.75,.19-1.03,.58s-.41,.91-.41,1.58v3.06h-.96v-6h.96v1.24h.02c.14-.42,.35-.75,.63-.99s.6-.35,.94-.35c.25,0,.44,.03,.57,.08v1Z"
                    />
                    <path
                      className="cls-2"
                      d="M92.9,10.76c-.17,0-.32-.06-.44-.18s-.18-.27-.18-.45,.06-.33,.18-.45,.27-.18,.44-.18,.33,.06,.45,.18,.18,.27,.18,.45-.06,.32-.18,.44-.27,.18-.45,.18Zm.47,7.52h-.96v-6h.96v6Z"
                    />
                    <path
                      className="cls-2"
                      d="M94.95,18.06v-1.03c.52,.39,1.1,.58,1.73,.58,.84,0,1.27-.28,1.27-.84,0-.16-.04-.3-.11-.41s-.17-.21-.29-.3-.27-.16-.43-.23-.34-.14-.54-.21c-.27-.11-.5-.21-.7-.32s-.37-.23-.5-.36-.24-.29-.3-.46-.1-.37-.1-.6c0-.28,.06-.53,.19-.75s.3-.4,.52-.54,.46-.26,.74-.33,.56-.11,.85-.11c.52,0,.98,.09,1.39,.27v.97c-.44-.29-.95-.43-1.52-.43-.18,0-.34,.02-.49,.06s-.27,.1-.37,.17-.18,.16-.24,.27-.08,.22-.08,.34c0,.16,.03,.29,.08,.39s.14,.2,.25,.28,.24,.16,.4,.22,.33,.14,.53,.22c.27,.1,.5,.21,.71,.31s.39,.23,.54,.36,.26,.29,.34,.47,.12,.38,.12,.63c0,.3-.07,.55-.2,.77s-.31,.4-.52,.54-.47,.25-.76,.32-.58,.11-.9,.11c-.62,0-1.15-.12-1.61-.36Z"
                    />
                    <path
                      className="cls-2"
                      d="M104.49,18.01c-.46,.28-1.01,.42-1.64,.42-.86,0-1.55-.28-2.07-.83s-.79-1.28-.79-2.17c0-.99,.28-1.78,.85-2.38s1.32-.9,2.27-.9c.53,0,.99,.1,1.39,.29v.98c-.45-.31-.92-.47-1.43-.47-.61,0-1.12,.22-1.51,.66s-.59,1.02-.59,1.73,.18,1.26,.55,1.66,.86,.61,1.49,.61c.52,0,1.02-.17,1.48-.52v.91Z"
                    />
                    <path
                      className="cls-2"
                      d="M110.93,18.28h-.96v-3.46c0-1.25-.46-1.88-1.39-1.88-.47,0-.86,.18-1.18,.54s-.48,.83-.48,1.39v3.4h-.96V9.4h.96v3.88h.02c.46-.76,1.12-1.14,1.97-1.14,1.35,0,2.03,.81,2.03,2.44v3.7Z"
                    />
                    <path
                      className="cls-2"
                      d="M117.56,15.52h-4.24c.02,.67,.2,1.18,.54,1.55s.82,.54,1.42,.54c.68,0,1.3-.22,1.86-.67v.9c-.53,.38-1.22,.57-2.09,.57s-1.51-.27-2-.82-.73-1.31-.73-2.3c0-.93,.26-1.69,.79-2.28s1.19-.88,1.97-.88,1.39,.25,1.82,.76,.64,1.21,.64,2.12v.5Zm-.98-.81c0-.55-.14-.99-.4-1.29s-.63-.46-1.1-.46-.84,.16-1.15,.49-.51,.75-.59,1.27h3.24Z"
                    />
                    <path
                      className="cls-2"
                      d="M128.67,9.88l-3.11,8.4h-1.08l-3.05-8.4h1.1l2.33,6.66c.07,.21,.13,.46,.17,.74h.02c.03-.23,.1-.49,.19-.76l2.37-6.65h1.06Z"
                    />
                    <path
                      className="cls-2"
                      d="M133.82,15.52h-4.24c.02,.67,.2,1.18,.54,1.55s.82,.54,1.42,.54c.68,0,1.3-.22,1.86-.67v.9c-.53,.38-1.22,.57-2.09,.57s-1.51-.27-2-.82-.73-1.31-.73-2.3c0-.93,.26-1.69,.79-2.28s1.19-.88,1.97-.88,1.39,.25,1.82,.76,.64,1.21,.64,2.12v.5Zm-.98-.81c0-.55-.14-.99-.4-1.29s-.63-.46-1.1-.46-.84,.16-1.15,.49-.51,.75-.59,1.27h3.24Z"
                    />
                    <path
                      className="cls-2"
                      d="M138.4,13.25c-.17-.13-.41-.19-.73-.19-.41,0-.75,.19-1.03,.58s-.41,.91-.41,1.58v3.06h-.96v-6h.96v1.24h.02c.14-.42,.35-.75,.63-.99s.6-.35,.94-.35c.25,0,.44,.03,.57,.08v1Z"
                    />
                    <path
                      className="cls-2"
                      d="M143.74,18.28h-.96v-.94h-.02c-.42,.72-1.03,1.08-1.85,1.08-.6,0-1.07-.16-1.4-.47s-.51-.74-.51-1.26c0-1.12,.66-1.77,1.98-1.96l1.8-.25c0-1.02-.41-1.53-1.24-1.53-.72,0-1.38,.25-1.96,.74v-.98c.59-.38,1.27-.56,2.04-.56,1.41,0,2.12,.75,2.12,2.24v3.9Zm-.96-3.04l-1.45,.2c-.45,.06-.78,.17-1.01,.33s-.34,.44-.34,.84c0,.29,.1,.53,.31,.72s.49,.28,.83,.28c.48,0,.87-.17,1.18-.5s.47-.76,.47-1.27v-.6Z"
                    />
                    <path
                      className="cls-2"
                      d="M150.53,18.28h-.96v-3.42c0-1.27-.46-1.91-1.39-1.91-.48,0-.88,.18-1.19,.54s-.47,.82-.47,1.37v3.42h-.96v-6h.96v1h.02c.45-.76,1.11-1.14,1.97-1.14,.66,0,1.16,.21,1.51,.64s.52,1.04,.52,1.84v3.67Z"
                    />
                    <path
                      className="cls-2"
                      d="M157.32,18.28h-1.35l-2.65-2.88h-.02v2.88h-.96V9.4h.96v5.63h.02l2.52-2.75h1.26l-2.78,2.89,3,3.11Z"
                    />
                    <path
                      className="cls-2"
                      d="M162.89,15.52h-4.24c.02,.67,.2,1.18,.54,1.55s.82,.54,1.42,.54c.68,0,1.3-.22,1.86-.67v.9c-.53,.38-1.22,.57-2.09,.57s-1.51-.27-2-.82-.73-1.31-.73-2.3c0-.93,.26-1.69,.79-2.28s1.19-.88,1.97-.88,1.39,.25,1.82,.76,.64,1.21,.64,2.12v.5Zm-.98-.81c0-.55-.14-.99-.4-1.29s-.63-.46-1.1-.46-.84,.16-1.15,.49-.51,.75-.59,1.27h3.24Z"
                    />
                    <path
                      className="cls-2"
                      d="M167.47,13.25c-.17-.13-.41-.19-.73-.19-.41,0-.75,.19-1.03,.58s-.41,.91-.41,1.58v3.06h-.96v-6h.96v1.24h.02c.14-.42,.35-.75,.63-.99s.6-.35,.94-.35c.25,0,.44,.03,.57,.08v1Z"
                    />
                    <path
                      className="cls-2"
                      d="M173.36,18.28h-.96v-.95h-.02c-.4,.73-1.02,1.09-1.85,1.09-1.43,0-2.14-.85-2.14-2.55v-3.59h.96v3.43c0,1.27,.48,1.9,1.45,1.9,.47,0,.85-.17,1.16-.52s.45-.8,.45-1.36v-3.46h.96v6Z"
                    />
                    <path
                      className="cls-2"
                      d="M180.28,18.28h-.96v-3.42c0-1.27-.46-1.91-1.39-1.91-.48,0-.88,.18-1.19,.54s-.47,.82-.47,1.37v3.42h-.96v-6h.96v1h.02c.45-.76,1.11-1.14,1.97-1.14,.66,0,1.16,.21,1.51,.64s.52,1.04,.52,1.84v3.67Z"
                    />
                    <path
                      className="cls-2"
                      d="M187.21,17.8c0,2.2-1.05,3.3-3.16,3.3-.74,0-1.39-.14-1.95-.42v-.96c.68,.38,1.32,.56,1.93,.56,1.48,0,2.21-.79,2.21-2.36v-.66h-.02c-.46,.77-1.14,1.15-2.06,1.15-.75,0-1.35-.27-1.8-.8s-.68-1.25-.68-2.15c0-1.02,.25-1.83,.74-2.43s1.16-.9,2.01-.9,1.41,.32,1.8,.97h.02v-.83h.96v5.52Zm-.96-2.23v-.88c0-.48-.16-.88-.48-1.22s-.72-.51-1.2-.51c-.59,0-1.06,.22-1.39,.65s-.5,1.04-.5,1.81c0,.67,.16,1.2,.48,1.6s.75,.6,1.28,.6,.98-.19,1.32-.57,.51-.87,.51-1.47Z"
                    />
                  </g>
                  <path
                    className="cls-1"
                    d="M46.5,35.68l3.09-5.35c.31-.54-.08-1.21-.7-1.21h-6.18c-.62,0-1.01,.67-.7,1.21l3.09,5.35c.31,.54,1.08,.54,1.39,0Z"
                  />
                  <circle className="cls-2" cx="14.65" cy="15.2" r="9.2" />
                  <path
                    className="cls-1"
                    d="M15.61,19.35h-.96v-7.26c-.07,.07-.18,.16-.33,.25s-.3,.19-.48,.28-.37,.18-.56,.26-.39,.15-.58,.19v-.97c.21-.06,.43-.14,.67-.24s.47-.21,.7-.33,.44-.25,.65-.39,.38-.27,.53-.4h.36v8.59Z"
                  />
                </g>
                </g>
                </Link>
              <Link to="/Identifikation">
                <g>
                  <path
                    className="cls-1"
                    d="M210.71,52.17l-5.35-3.09c-.54-.31-1.21,.08-1.21,.7v6.18c0,.62,.67,1.01,1.21,.7l5.35-3.09c.54-.31,.54-1.08,0-1.39Z"
                  />
                  <g>
                    <rect
                      className="cls-1"
                      x="0"
                      y="37.17"
                      width="208.17"
                      height="31.3"
                      rx="15.65"
                      ry="15.65"
                    />
                    <g>
                      <path
                        className="cls-2"
                        d="M66.96,56.99h-1.17l-1.41-2.36c-.13-.22-.25-.41-.38-.56s-.25-.28-.37-.38-.26-.17-.41-.21-.31-.07-.5-.07h-.81v3.57h-.98v-8.4h2.51c.37,0,.71,.05,1.02,.14s.58,.23,.81,.42,.41,.42,.54,.7,.19,.61,.19,.98c0,.29-.04,.56-.13,.81s-.21,.46-.38,.65-.36,.35-.59,.49-.49,.24-.77,.31v.02c.14,.06,.26,.13,.37,.21s.2,.17,.3,.28,.19,.23,.28,.37,.19,.3,.31,.48l1.58,2.53Zm-5.04-7.51v3.05h1.34c.25,0,.47-.04,.68-.11s.39-.18,.54-.32,.27-.31,.36-.51,.13-.43,.13-.68c0-.45-.15-.8-.44-1.05s-.71-.38-1.26-.38h-1.35Z"
                      />
                      <path
                        className="cls-2"
                        d="M68.47,49.46c-.17,0-.32-.06-.44-.18s-.18-.27-.18-.45,.06-.33,.18-.45,.27-.18,.44-.18,.33,.06,.45,.18,.18,.27,.18,.45-.06,.32-.18,.44-.27,.18-.45,.18Zm.47,7.52h-.96v-6h.96v6Z"
                      />
                      <path
                        className="cls-2"
                        d="M70.53,56.77v-1.03c.52,.39,1.1,.58,1.73,.58,.84,0,1.27-.28,1.27-.84,0-.16-.04-.3-.11-.41s-.17-.21-.29-.3-.27-.16-.43-.23-.34-.14-.54-.21c-.27-.11-.5-.21-.7-.32s-.37-.23-.5-.36-.24-.29-.3-.46-.1-.37-.1-.6c0-.28,.06-.53,.19-.75s.3-.4,.52-.54,.46-.26,.74-.33,.56-.11,.85-.11c.52,0,.98,.09,1.39,.27v.97c-.44-.29-.95-.43-1.52-.43-.18,0-.34,.02-.49,.06s-.27,.1-.37,.17-.18,.16-.24,.27-.08,.22-.08,.34c0,.16,.03,.29,.08,.39s.14,.2,.25,.28,.24,.16,.4,.22,.33,.14,.53,.22c.27,.1,.5,.21,.71,.31s.39,.23,.54,.36,.26,.29,.34,.47,.12,.38,.12,.63c0,.3-.07,.55-.2,.77s-.31,.4-.52,.54-.47,.25-.76,.32-.58,.11-.9,.11c-.62,0-1.15-.12-1.61-.36Z"
                      />
                      <path
                        className="cls-2"
                        d="M76.47,49.46c-.17,0-.32-.06-.44-.18s-.18-.27-.18-.45,.06-.33,.18-.45,.27-.18,.44-.18,.33,.06,.45,.18,.18,.27,.18,.45-.06,.32-.18,.44-.27,.18-.45,.18Zm.47,7.52h-.96v-6h.96v6Z"
                      />
                      <path
                        className="cls-2"
                        d="M83.87,56.99h-1.35l-2.65-2.88h-.02v2.88h-.96v-8.88h.96v5.63h.02l2.52-2.75h1.26l-2.78,2.89,3,3.11Z"
                      />
                      <path
                        className="cls-2"
                        d="M87.12,57.13c-.89,0-1.59-.28-2.12-.84s-.79-1.3-.79-2.23c0-1.01,.28-1.79,.83-2.36s1.29-.85,2.23-.85,1.59,.28,2.09,.83,.75,1.31,.75,2.29-.27,1.72-.81,2.3-1.27,.86-2.18,.86Zm.07-5.47c-.62,0-1.11,.21-1.46,.63s-.54,1-.54,1.74,.18,1.27,.54,1.68,.85,.62,1.46,.62,1.1-.2,1.43-.6,.5-.97,.5-1.72-.17-1.33-.5-1.73-.81-.61-1.43-.61Z"
                      />
                      <path className="cls-2" d="M94.71,54.02h-3.2v-.76h3.2v.76Z" />
                      <path className="cls-2" d="M97.55,56.99h-.98v-8.4h.98v8.4Z" />
                      <path
                        className="cls-2"
                        d="M104.76,56.99h-.96v-1.02h-.02c-.45,.77-1.13,1.16-2.06,1.16-.75,0-1.36-.27-1.81-.81s-.68-1.27-.68-2.19c0-.99,.25-1.79,.75-2.38s1.17-.9,2-.9,1.42,.32,1.8,.97h.02v-3.71h.96v8.88Zm-.96-2.71v-.88c0-.48-.16-.89-.48-1.23s-.73-.5-1.22-.5c-.59,0-1.05,.21-1.38,.64s-.5,1.02-.5,1.78c0,.69,.16,1.24,.48,1.64s.75,.6,1.3,.6,.97-.19,1.3-.58,.5-.88,.5-1.46Z"
                      />
                      <path
                        className="cls-2"
                        d="M111.52,54.23h-4.24c.02,.67,.2,1.18,.54,1.55s.82,.54,1.42,.54c.68,0,1.3-.22,1.86-.67v.9c-.53,.38-1.22,.57-2.09,.57s-1.51-.27-2-.82-.73-1.31-.73-2.3c0-.93,.26-1.69,.79-2.28s1.19-.88,1.97-.88,1.39,.25,1.82,.76,.64,1.21,.64,2.12v.5Zm-.98-.81c0-.55-.14-.99-.4-1.29s-.63-.46-1.1-.46-.84,.16-1.15,.49-.51,.75-.59,1.27h3.24Z"
                      />
                      <path
                        className="cls-2"
                        d="M117.96,56.99h-.96v-3.42c0-1.27-.46-1.91-1.39-1.91-.48,0-.88,.18-1.19,.54s-.47,.82-.47,1.37v3.42h-.96v-6h.96v1h.02c.45-.76,1.11-1.14,1.97-1.14,.66,0,1.16,.21,1.51,.64s.52,1.04,.52,1.84v3.67Z"
                      />
                      <path
                        className="cls-2"
                        d="M122.55,56.93c-.23,.12-.53,.19-.9,.19-1.05,0-1.58-.59-1.58-1.76v-3.55h-1.03v-.82h1.03v-1.46l.96-.31v1.78h1.51v.82h-1.51v3.38c0,.4,.07,.69,.21,.86s.36,.26,.68,.26c.24,0,.45-.07,.63-.2v.82Z"
                      />
                      <path
                        className="cls-2"
                        d="M124.33,49.46c-.17,0-.32-.06-.44-.18s-.18-.27-.18-.45,.06-.33,.18-.45,.27-.18,.44-.18,.33,.06,.45,.18,.18,.27,.18,.45-.06,.32-.18,.44-.27,.18-.45,.18Zm.47,7.52h-.96v-6h.96v6Z"
                      />
                      <path
                        className="cls-2"
                        d="M129.71,48.95c-.19-.11-.4-.16-.64-.16-.67,0-1.01,.42-1.01,1.27v.93h1.41v.82h-1.41v5.18h-.96v-5.18h-1.03v-.82h1.03v-.97c0-.63,.18-1.13,.54-1.49s.82-.55,1.36-.55c.29,0,.53,.04,.7,.11v.87Z"
                      />
                      <path
                        className="cls-2"
                        d="M130.99,49.46c-.17,0-.32-.06-.44-.18s-.18-.27-.18-.45,.06-.33,.18-.45,.27-.18,.44-.18,.33,.06,.45,.18,.18,.27,.18,.45-.06,.32-.18,.44-.27,.18-.45,.18Zm.47,7.52h-.96v-6h.96v6Z"
                      />
                      <path
                        className="cls-2"
                        d="M138.38,56.99h-1.35l-2.65-2.88h-.02v2.88h-.96v-8.88h.96v5.63h.02l2.52-2.75h1.26l-2.78,2.89,3,3.11Z"
                      />
                      <path
                        className="cls-2"
                        d="M143.66,56.99h-.96v-.94h-.02c-.42,.72-1.03,1.08-1.85,1.08-.6,0-1.07-.16-1.4-.47s-.51-.74-.51-1.26c0-1.12,.66-1.77,1.98-1.96l1.8-.25c0-1.02-.41-1.53-1.24-1.53-.72,0-1.38,.25-1.96,.74v-.98c.59-.38,1.27-.56,2.04-.56,1.41,0,2.12,.75,2.12,2.24v3.9Zm-.96-3.04l-1.45,.2c-.45,.06-.78,.17-1.01,.33s-.34,.44-.34,.84c0,.29,.1,.53,.31,.72s.49,.28,.83,.28c.48,0,.87-.17,1.18-.5s.47-.76,.47-1.27v-.6Z"
                      />
                      <path
                        className="cls-2"
                        d="M148.26,56.93c-.23,.12-.53,.19-.9,.19-1.05,0-1.58-.59-1.58-1.76v-3.55h-1.03v-.82h1.03v-1.46l.96-.31v1.78h1.51v.82h-1.51v3.38c0,.4,.07,.69,.21,.86s.36,.26,.68,.26c.24,0,.45-.07,.63-.2v.82Z"
                      />
                      <path
                        className="cls-2"
                        d="M150.03,49.46c-.17,0-.32-.06-.44-.18s-.18-.27-.18-.45,.06-.33,.18-.45,.27-.18,.44-.18,.33,.06,.45,.18,.18,.27,.18,.45-.06,.32-.18,.44-.27,.18-.45,.18Zm.47,7.52h-.96v-6h.96v6Z"
                      />
                      <path
                        className="cls-2"
                        d="M154.95,57.13c-.89,0-1.59-.28-2.12-.84s-.79-1.3-.79-2.23c0-1.01,.28-1.79,.83-2.36s1.29-.85,2.23-.85,1.59,.28,2.09,.83,.75,1.31,.75,2.29-.27,1.72-.81,2.3-1.27,.86-2.18,.86Zm.07-5.47c-.62,0-1.11,.21-1.46,.63s-.54,1-.54,1.74,.18,1.27,.54,1.68,.85,.62,1.46,.62,1.1-.2,1.43-.6,.5-.97,.5-1.72-.17-1.33-.5-1.73-.81-.61-1.43-.61Z"
                      />
                      <path
                        className="cls-2"
                        d="M164.46,56.99h-.96v-3.42c0-1.27-.46-1.91-1.39-1.91-.48,0-.88,.18-1.19,.54s-.47,.82-.47,1.37v3.42h-.96v-6h.96v1h.02c.45-.76,1.11-1.14,1.97-1.14,.66,0,1.16,.21,1.51,.64s.52,1.04,.52,1.84v3.67Z"
                      />
                    </g>
                    <path
                      className="cls-1"
                      d="M46.24,72.99l3.09-5.35c.31-.54-.08-1.21-.7-1.21h-6.18c-.62,0-1.01,.67-.7,1.21l3.09,5.35c.31,.54,1.08,.54,1.39,0Z"
                    />
                    <circle className="cls-2" cx="15.04" cy="52.27" r="9.2" />
                    <path
                      className="cls-1"
                      d="M15.83,50.43c0-.26-.04-.48-.12-.67s-.19-.35-.33-.48-.3-.22-.49-.28-.39-.09-.61-.09c-.19,0-.37,.03-.55,.08s-.35,.12-.52,.21-.33,.2-.49,.32-.31,.26-.44,.42v-1.03c.27-.26,.57-.46,.91-.6s.74-.21,1.22-.21c.34,0,.66,.05,.96,.15s.55,.25,.76,.44,.38,.43,.51,.71,.18,.6,.18,.97c0,.33-.04,.63-.11,.9s-.19,.52-.35,.76-.35,.47-.58,.69-.51,.44-.83,.67c-.39,.28-.72,.52-.98,.72s-.46,.39-.61,.56-.26,.35-.32,.52-.09,.37-.09,.6h4.18v.86h-5.19v-.42c0-.36,.04-.68,.12-.95s.21-.53,.39-.78,.42-.49,.71-.74,.65-.52,1.07-.83c.3-.22,.56-.43,.76-.63s.37-.4,.5-.6,.21-.4,.27-.61,.08-.42,.08-.65Z"
                    />
                  </g>
                </g>
              </Link>
            <Link to="/Analyse">
              <g>
                <path
                  className="cls-1"
                  d="M210.69,89.6l-5.35-3.09c-.54-.31-1.21,.08-1.21,.7v6.18c0,.62,.67,1.01,1.21,.7l5.35-3.09c.54-.31,.54-1.08,0-1.39Z"
                />
                <g>
                  <rect
                    className="cls-1"
                    x="0"
                    y="74.35"
                    width="208.17"
                    height="31.3"
                    rx="15.65"
                    ry="15.65"
                  />
                  <g>
                    <path
                      className="cls-2"
                      d="M79.41,93.11h-1.17l-1.41-2.36c-.13-.22-.25-.41-.38-.56s-.25-.28-.37-.38-.26-.17-.41-.21-.31-.07-.5-.07h-.81v3.57h-.98v-8.4h2.51c.37,0,.71,.05,1.02,.14s.58,.23,.81,.42,.41,.42,.54,.7,.19,.61,.19,.98c0,.29-.04,.56-.13,.81s-.21,.46-.38,.65-.36,.35-.59,.49-.49,.24-.77,.31v.02c.14,.06,.26,.13,.37,.21s.2,.17,.3,.28,.19,.23,.28,.37,.19,.3,.31,.48l1.58,2.53Zm-5.04-7.51v3.05h1.34c.25,0,.47-.04,.68-.11s.39-.18,.54-.32,.27-.31,.36-.51,.13-.43,.13-.68c0-.45-.15-.8-.44-1.05s-.71-.38-1.26-.38h-1.35Z"
                    />
                    <path
                      className="cls-2"
                      d="M80.92,85.59c-.17,0-.32-.06-.44-.18s-.18-.27-.18-.45,.06-.33,.18-.45,.27-.18,.44-.18,.33,.06,.45,.18,.18,.27,.18,.45-.06,.32-.18,.44-.27,.18-.45,.18Zm.47,7.52h-.96v-6h.96v6Z"
                    />
                    <path
                      className="cls-2"
                      d="M82.98,92.9v-1.03c.52,.39,1.1,.58,1.73,.58,.84,0,1.27-.28,1.27-.84,0-.16-.04-.3-.11-.41s-.17-.21-.29-.3-.27-.16-.43-.23-.34-.14-.54-.21c-.27-.11-.5-.21-.7-.32s-.37-.23-.5-.36-.24-.29-.3-.46-.1-.37-.1-.6c0-.28,.06-.53,.19-.75s.3-.4,.52-.54,.46-.26,.74-.33,.56-.11,.85-.11c.52,0,.98,.09,1.39,.27v.97c-.44-.29-.95-.43-1.52-.43-.18,0-.34,.02-.49,.06s-.27,.1-.37,.17-.18,.16-.24,.27-.08,.22-.08,.34c0,.16,.03,.29,.08,.39s.14,.2,.25,.28,.24,.16,.4,.22,.33,.14,.53,.22c.27,.1,.5,.21,.71,.31s.39,.23,.54,.36,.26,.29,.34,.47,.12,.38,.12,.63c0,.3-.07,.55-.2,.77s-.31,.4-.52,.54-.47,.25-.76,.32-.58,.11-.9,.11c-.62,0-1.15-.12-1.61-.36Z"
                    />
                    <path
                      className="cls-2"
                      d="M88.92,85.59c-.17,0-.32-.06-.44-.18s-.18-.27-.18-.45,.06-.33,.18-.45,.27-.18,.44-.18,.33,.06,.45,.18,.18,.27,.18,.45-.06,.32-.18,.44-.27,.18-.45,.18Zm.47,7.52h-.96v-6h.96v6Z"
                    />
                    <path
                      className="cls-2"
                      d="M96.32,93.11h-1.35l-2.65-2.88h-.02v2.88h-.96v-8.88h.96v5.63h.02l2.52-2.75h1.26l-2.78,2.89,3,3.11Z"
                    />
                    <path
                      className="cls-2"
                      d="M99.57,93.25c-.89,0-1.59-.28-2.12-.84s-.79-1.3-.79-2.23c0-1.01,.28-1.79,.83-2.36s1.29-.85,2.23-.85,1.59,.28,2.09,.83,.75,1.31,.75,2.29-.27,1.72-.81,2.3-1.27,.86-2.18,.86Zm.07-5.47c-.62,0-1.11,.21-1.46,.63s-.54,1-.54,1.74,.18,1.27,.54,1.68,.85,.62,1.46,.62,1.1-.2,1.43-.6,.5-.97,.5-1.72-.17-1.33-.5-1.73-.81-.61-1.43-.61Z"
                    />
                    <path className="cls-2" d="M107.16,90.15h-3.2v-.76h3.2v.76Z" />
                    <path
                      className="cls-2"
                      d="M115.52,93.11h-1.09l-.89-2.36h-3.56l-.84,2.36h-1.1l3.22-8.4h1.02l3.23,8.4Zm-2.3-3.24l-1.32-3.58c-.04-.12-.09-.3-.13-.56h-.02c-.04,.24-.08,.43-.13,.56l-1.31,3.58h2.91Z"
                    />
                    <path
                      className="cls-2"
                      d="M121.61,93.11h-.96v-3.42c0-1.27-.46-1.91-1.39-1.91-.48,0-.88,.18-1.19,.54s-.47,.82-.47,1.37v3.42h-.96v-6h.96v1h.02c.45-.76,1.11-1.14,1.97-1.14,.66,0,1.16,.21,1.51,.64s.52,1.04,.52,1.84v3.67Z"
                    />
                    <path
                      className="cls-2"
                      d="M127.72,93.11h-.96v-.94h-.02c-.42,.72-1.03,1.08-1.85,1.08-.6,0-1.07-.16-1.4-.47s-.51-.74-.51-1.26c0-1.12,.66-1.77,1.98-1.96l1.8-.25c0-1.02-.41-1.53-1.24-1.53-.72,0-1.38,.25-1.96,.74v-.98c.59-.38,1.27-.56,2.04-.56,1.41,0,2.12,.75,2.12,2.24v3.9Zm-.96-3.04l-1.45,.2c-.45,.06-.78,.17-1.01,.33s-.34,.44-.34,.84c0,.29,.1,.53,.31,.72s.49,.28,.83,.28c.48,0,.87-.17,1.18-.5s.47-.76,.47-1.27v-.6Z"
                    />
                    <path
                      className="cls-2"
                      d="M130.49,93.11h-.96v-8.88h.96v8.88Z"
                    />
                    <path
                      className="cls-2"
                      d="M137.21,87.11l-2.76,6.96c-.49,1.24-1.18,1.86-2.07,1.86-.25,0-.46-.03-.63-.08v-.86c.21,.07,.4,.11,.57,.11,.48,0,.85-.29,1.09-.87l.48-1.14-2.34-5.99h1.07l1.62,4.62c.02,.06,.06,.21,.12,.46h.04c.02-.09,.06-.24,.12-.45l1.71-4.63h1Z"
                    />
                    <path
                      className="cls-2"
                      d="M137.88,92.9v-1.03c.52,.39,1.1,.58,1.73,.58,.84,0,1.27-.28,1.27-.84,0-.16-.04-.3-.11-.41s-.17-.21-.29-.3-.27-.16-.43-.23-.34-.14-.54-.21c-.27-.11-.5-.21-.7-.32s-.37-.23-.5-.36-.24-.29-.3-.46-.1-.37-.1-.6c0-.28,.06-.53,.19-.75s.3-.4,.52-.54,.46-.26,.74-.33,.56-.11,.85-.11c.52,0,.98,.09,1.39,.27v.97c-.44-.29-.95-.43-1.52-.43-.18,0-.34,.02-.49,.06s-.27,.1-.37,.17-.18,.16-.24,.27-.08,.22-.08,.34c0,.16,.03,.29,.08,.39s.14,.2,.25,.28,.24,.16,.4,.22,.33,.14,.53,.22c.27,.1,.5,.21,.71,.31s.39,.23,.54,.36,.26,.29,.34,.47,.12,.38,.12,.63c0,.3-.07,.55-.2,.77s-.31,.4-.52,.54-.47,.25-.76,.32-.58,.11-.9,.11c-.62,0-1.15-.12-1.61-.36Z"
                    />
                    <path
                      className="cls-2"
                      d="M148.16,90.35h-4.24c.02,.67,.2,1.18,.54,1.55s.82,.54,1.42,.54c.68,0,1.3-.22,1.86-.67v.9c-.53,.38-1.22,.57-2.09,.57s-1.51-.27-2-.82-.73-1.31-.73-2.3c0-.93,.26-1.69,.79-2.28s1.19-.88,1.97-.88,1.39,.25,1.82,.76,.64,1.21,.64,2.12v.5Zm-.98-.81c0-.55-.14-.99-.4-1.29s-.63-.46-1.1-.46-.84,.16-1.15,.49-.51,.75-.59,1.27h3.24Z"
                    />
                  </g>
                  <path
                    className="cls-1"
                    d="M46.18,110.03l3.09-5.35c.31-.54-.08-1.21-.7-1.21h-6.18c-.62,0-1.01,.67-.7,1.21l3.09,5.35c.31,.54,1.08,.54,1.39,0Z"
                  />
                  <circle className="cls-2" cx="14.45" cy="89.35" r="9.2" />
                  <path
                    className="cls-1"
                    d="M12.15,93.54v-1.04c.57,.45,1.22,.68,1.95,.68,.59,0,1.05-.15,1.4-.44s.52-.69,.52-1.18c0-1.1-.78-1.65-2.34-1.65h-.7v-.81h.66c1.39,0,2.08-.52,2.08-1.55,0-.95-.53-1.43-1.59-1.43-.59,0-1.15,.2-1.67,.6v-.94c.54-.32,1.18-.48,1.91-.48s1.27,.19,1.7,.56,.64,.86,.64,1.44c0,1.11-.56,1.82-1.69,2.13v.02c.61,.06,1.09,.27,1.44,.64s.54,.82,.54,1.37c0,.75-.27,1.36-.81,1.83s-1.25,.69-2.14,.69c-.8,0-1.43-.15-1.9-.45Z"
                  />
                </g>
              </g>
              </Link>
            <Link to="/Analyse">
              <g>
                <path
                  className="cls-1"
                  d="M211.04,127.04l-5.35-3.09c-.54-.31-1.21,.08-1.21,.7v6.18c0,.62,.67,1.01,1.21,.7l5.35-3.09c.54-.31,.54-1.08,0-1.39Z"
                />
                <g>
                  <rect
                    className="cls-1"
                    x="0"
                    y="111.52"
                    width="208.17"
                    height="31.3"
                    rx="15.65"
                    ry="15.65"
                  />
                  <g>
                    <path
                      className="cls-2"
                      d="M69.95,130.64h-1.17l-1.41-2.36c-.13-.22-.25-.41-.38-.56s-.25-.28-.37-.38-.26-.17-.41-.21-.31-.07-.5-.07h-.81v3.57h-.98v-8.4h2.51c.37,0,.71,.05,1.02,.14s.58,.23,.81,.42,.41,.42,.54,.7,.19,.61,.19,.98c0,.29-.04,.56-.13,.81s-.21,.46-.38,.65-.36,.35-.59,.49-.49,.24-.77,.31v.02c.14,.06,.26,.13,.37,.21s.2,.17,.3,.28,.19,.23,.28,.37,.19,.3,.31,.48l1.58,2.53Zm-5.04-7.51v3.05h1.34c.25,0,.47-.04,.68-.11s.39-.18,.54-.32,.27-.31,.36-.51,.13-.43,.13-.68c0-.45-.15-.8-.44-1.05s-.71-.38-1.26-.38h-1.35Z"
                    />
                    <path
                      className="cls-2"
                      d="M71.47,123.11c-.17,0-.32-.06-.44-.18s-.18-.27-.18-.45,.06-.33,.18-.45,.27-.18,.44-.18,.33,.06,.45,.18,.18,.27,.18,.45-.06,.32-.18,.44-.27,.18-.45,.18Zm.47,7.52h-.96v-6h.96v6Z"
                    />
                    <path
                      className="cls-2"
                      d="M73.52,130.42v-1.03c.52,.39,1.1,.58,1.73,.58,.84,0,1.27-.28,1.27-.84,0-.16-.04-.3-.11-.41s-.17-.21-.29-.3-.27-.16-.43-.23-.34-.14-.54-.21c-.27-.11-.5-.21-.7-.32s-.37-.23-.5-.36-.24-.29-.3-.46-.1-.37-.1-.6c0-.28,.06-.53,.19-.75s.3-.4,.52-.54,.46-.26,.74-.33,.56-.11,.85-.11c.52,0,.98,.09,1.39,.27v.97c-.44-.29-.95-.43-1.52-.43-.18,0-.34,.02-.49,.06s-.27,.1-.37,.17-.18,.16-.24,.27-.08,.22-.08,.34c0,.16,.03,.29,.08,.39s.14,.2,.25,.28,.24,.16,.4,.22,.33,.14,.53,.22c.27,.1,.5,.21,.71,.31s.39,.23,.54,.36,.26,.29,.34,.47,.12,.38,.12,.63c0,.3-.07,.55-.2,.77s-.31,.4-.52,.54-.47,.25-.76,.32-.58,.11-.9,.11c-.62,0-1.15-.12-1.61-.36Z"
                    />
                    <path
                      className="cls-2"
                      d="M79.47,123.11c-.17,0-.32-.06-.44-.18s-.18-.27-.18-.45,.06-.33,.18-.45,.27-.18,.44-.18,.33,.06,.45,.18,.18,.27,.18,.45-.06,.32-.18,.44-.27,.18-.45,.18Zm.47,7.52h-.96v-6h.96v6Z"
                    />
                    <path
                      className="cls-2"
                      d="M86.86,130.64h-1.35l-2.65-2.88h-.02v2.88h-.96v-8.88h.96v5.63h.02l2.52-2.75h1.26l-2.78,2.89,3,3.11Z"
                    />
                    <path
                      className="cls-2"
                      d="M90.12,130.78c-.89,0-1.59-.28-2.12-.84s-.79-1.3-.79-2.23c0-1.01,.28-1.79,.83-2.36s1.29-.85,2.23-.85,1.59,.28,2.09,.83,.75,1.31,.75,2.29-.27,1.72-.81,2.3-1.27,.86-2.18,.86Zm.07-5.47c-.62,0-1.11,.21-1.46,.63s-.54,1-.54,1.74,.18,1.27,.54,1.68,.85,.62,1.46,.62,1.1-.2,1.43-.6,.5-.97,.5-1.72-.17-1.33-.5-1.73-.81-.61-1.43-.61Z"
                    />
                    <path className="cls-2" d="M97.71,127.67h-3.2v-.76h3.2v.76Z" />
                    <path
                      className="cls-2"
                      d="M99.57,130.64v-8.4h2.39c.73,0,1.3,.18,1.73,.53s.64,.82,.64,1.39c0,.48-.13,.89-.39,1.24s-.61,.6-1.07,.75v.02c.57,.07,1.02,.28,1.36,.64s.51,.83,.51,1.41c0,.72-.26,1.3-.77,1.75s-1.17,.67-1.95,.67h-2.45Zm.98-7.51v2.71h1.01c.54,0,.96-.13,1.27-.39s.46-.63,.46-1.1c0-.82-.54-1.22-1.61-1.22h-1.13Zm0,3.6v3.02h1.34c.58,0,1.03-.14,1.34-.41s.48-.65,.48-1.12c0-.99-.68-1.49-2.03-1.49h-1.13Z"
                    />
                    <path
                      className="cls-2"
                      d="M111.14,127.88h-4.24c.02,.67,.2,1.18,.54,1.55s.82,.54,1.42,.54c.68,0,1.3-.22,1.86-.67v.9c-.53,.38-1.22,.57-2.09,.57s-1.51-.27-2-.82-.73-1.31-.73-2.3c0-.93,.26-1.69,.79-2.28s1.19-.88,1.97-.88,1.39,.25,1.82,.76,.64,1.21,.64,2.12v.5Zm-.98-.81c0-.55-.14-.99-.4-1.29s-.63-.46-1.1-.46-.84,.16-1.15,.49-.51,.75-.59,1.27h3.24Z"
                    />
                    <path
                      className="cls-2"
                      d="M120.15,124.64l-1.8,6h-1l-1.24-4.29c-.05-.16-.08-.35-.09-.56h-.02c-.01,.14-.05,.32-.12,.54l-1.34,4.31h-.96l-1.82-6h1.01l1.24,4.51c.04,.14,.07,.32,.08,.54h.05c.01-.17,.05-.36,.11-.55l1.38-4.5h.88l1.24,4.52c.04,.14,.07,.32,.09,.54h.05c0-.15,.04-.33,.1-.54l1.22-4.52h.95Z"
                    />
                    <path
                      className="cls-2"
                      d="M126.03,127.88h-4.24c.02,.67,.2,1.18,.54,1.55s.82,.54,1.42,.54c.68,0,1.3-.22,1.86-.67v.9c-.53,.38-1.22,.57-2.09,.57s-1.51-.27-2-.82-.73-1.31-.73-2.3c0-.93,.26-1.69,.79-2.28s1.19-.88,1.97-.88,1.39,.25,1.82,.76,.64,1.21,.64,2.12v.5Zm-.98-.81c0-.55-.14-.99-.4-1.29s-.63-.46-1.1-.46-.84,.16-1.15,.49-.51,.75-.59,1.27h3.24Z"
                    />
                    <path
                      className="cls-2"
                      d="M130.61,125.61c-.17-.13-.41-.19-.73-.19-.41,0-.75,.19-1.03,.58s-.41,.91-.41,1.58v3.06h-.96v-6h.96v1.24h.02c.14-.42,.35-.75,.63-.99s.6-.35,.94-.35c.25,0,.44,.03,.57,.08v1Z"
                    />
                    <path
                      className="cls-2"
                      d="M134.78,130.58c-.23,.12-.53,.19-.9,.19-1.05,0-1.58-.59-1.58-1.76v-3.55h-1.03v-.82h1.03v-1.46l.96-.31v1.78h1.51v.82h-1.51v3.38c0,.4,.07,.69,.21,.86s.36,.26,.68,.26c.24,0,.45-.07,.63-.2v.82Z"
                    />
                    <path
                      className="cls-2"
                      d="M140.92,130.64h-.96v-.95h-.02c-.4,.73-1.02,1.09-1.85,1.09-1.43,0-2.14-.85-2.14-2.55v-3.59h.96v3.43c0,1.27,.48,1.9,1.45,1.9,.47,0,.85-.17,1.16-.52s.45-.8,.45-1.36v-3.46h.96v6Z"
                    />
                    <path
                      className="cls-2"
                      d="M147.84,130.64h-.96v-3.42c0-1.27-.46-1.91-1.39-1.91-.48,0-.88,.18-1.19,.54s-.47,.82-.47,1.37v3.42h-.96v-6h.96v1h.02c.45-.76,1.11-1.14,1.97-1.14,.66,0,1.16,.21,1.51,.64s.52,1.04,.52,1.84v3.67Z"
                    />
                    <path
                      className="cls-2"
                      d="M154.77,130.16c0,2.2-1.05,3.3-3.16,3.3-.74,0-1.39-.14-1.95-.42v-.96c.68,.38,1.32,.56,1.93,.56,1.48,0,2.21-.79,2.21-2.36v-.66h-.02c-.46,.77-1.14,1.15-2.06,1.15-.75,0-1.35-.27-1.8-.8s-.68-1.25-.68-2.15c0-1.02,.25-1.83,.74-2.43s1.16-.9,2.01-.9,1.41,.32,1.8,.97h.02v-.83h.96v5.52Zm-.96-2.23v-.88c0-.48-.16-.88-.48-1.22s-.72-.51-1.2-.51c-.59,0-1.06,.22-1.39,.65s-.5,1.04-.5,1.81c0,.67,.16,1.2,.48,1.6s.75,.6,1.28,.6,.98-.19,1.32-.57,.51-.87,.51-1.47Z"
                    />
                  </g>
                  <path
                    className="cls-1"
                    d="M46.24,147.47l3.09-5.35c.31-.54-.08-1.21-.7-1.21h-6.18c-.62,0-1.01,.67-.7,1.21l3.09,5.35c.31,.54,1.08,.54,1.39,0Z"
                  />
                  <circle className="cls-2" cx="14.85" cy="126.42" r="9.2" />
                  <path
                    className="cls-1"
                    d="M16.5,122.74v5.55h1.08v.88h-1.08v1.97h-.95v-1.97h-3.95v-.83c.37-.41,.74-.86,1.11-1.33s.73-.95,1.08-1.43,.66-.97,.95-1.45,.54-.94,.74-1.39h1.03Zm-3.81,5.55h2.87v-4.11c-.29,.51-.57,.97-.83,1.37s-.51,.77-.75,1.1-.46,.62-.68,.89-.42,.52-.61,.76Z"
                  />
                </g>
              </g>
              </Link>
              <Link to="/Bewältigung">
              <g>
                <path
                  className="cls-1"
                  d="M211.54,163.3l-5.35-3.09c-.54-.31-1.21,.08-1.21,.7v6.18c0,.62,.67,1.01,1.21,.7l5.35-3.09c.54-.31,.54-1.08,0-1.39Z"
                />
                <g>
                  <rect
                    className="cls-1"
                    x="0"
                    y="148.7"
                    width="208.17"
                    height="31.3"
                    rx="15.65"
                    ry="15.65"
                  />
                  <g>
                    <path
                      className="cls-2"
                      d="M66.39,168.09h-1.17l-1.41-2.36c-.13-.22-.25-.41-.38-.56s-.25-.28-.37-.38-.26-.17-.41-.21-.31-.07-.5-.07h-.81v3.57h-.98v-8.4h2.51c.37,0,.71,.05,1.02,.14s.58,.23,.81,.42,.41,.42,.54,.7,.19,.61,.19,.98c0,.29-.04,.56-.13,.81s-.21,.46-.38,.65-.36,.35-.59,.49-.49,.24-.77,.31v.02c.14,.06,.26,.13,.37,.21s.2,.17,.3,.28,.19,.23,.28,.37,.19,.3,.31,.48l1.58,2.53Zm-5.04-7.51v3.05h1.34c.25,0,.47-.04,.68-.11s.39-.18,.54-.32,.27-.31,.36-.51,.13-.43,.13-.68c0-.45-.15-.8-.44-1.05s-.71-.38-1.26-.38h-1.35Z"
                    />
                    <path
                      className="cls-2"
                      d="M67.91,160.57c-.17,0-.32-.06-.44-.18s-.18-.27-.18-.45,.06-.33,.18-.45,.27-.18,.44-.18,.33,.06,.45,.18,.18,.27,.18,.45-.06,.32-.18,.44-.27,.18-.45,.18Zm.47,7.52h-.96v-6h.96v6Z"
                    />
                    <path
                      className="cls-2"
                      d="M69.96,167.87v-1.03c.52,.39,1.1,.58,1.73,.58,.84,0,1.27-.28,1.27-.84,0-.16-.04-.3-.11-.41s-.17-.21-.29-.3-.27-.16-.43-.23-.34-.14-.54-.21c-.27-.11-.5-.21-.7-.32s-.37-.23-.5-.36-.24-.29-.3-.46-.1-.37-.1-.6c0-.28,.06-.53,.19-.75s.3-.4,.52-.54,.46-.26,.74-.33,.56-.11,.85-.11c.52,0,.98,.09,1.39,.27v.97c-.44-.29-.95-.43-1.52-.43-.18,0-.34,.02-.49,.06s-.27,.1-.37,.17-.18,.16-.24,.27-.08,.22-.08,.34c0,.16,.03,.29,.08,.39s.14,.2,.25,.28,.24,.16,.4,.22,.33,.14,.53,.22c.27,.1,.5,.21,.71,.31s.39,.23,.54,.36,.26,.29,.34,.47,.12,.38,.12,.63c0,.3-.07,.55-.2,.77s-.31,.4-.52,.54-.47,.25-.76,.32-.58,.11-.9,.11c-.62,0-1.15-.12-1.61-.36Z"
                    />
                    <path
                      className="cls-2"
                      d="M75.91,160.57c-.17,0-.32-.06-.44-.18s-.18-.27-.18-.45,.06-.33,.18-.45,.27-.18,.44-.18,.33,.06,.45,.18,.18,.27,.18,.45-.06,.32-.18,.44-.27,.18-.45,.18Zm.47,7.52h-.96v-6h.96v6Z"
                    />
                    <path
                      className="cls-2"
                      d="M83.3,168.09h-1.35l-2.65-2.88h-.02v2.88h-.96v-8.88h.96v5.63h.02l2.52-2.75h1.26l-2.78,2.89,3,3.11Z"
                    />
                    <path
                      className="cls-2"
                      d="M86.55,168.23c-.89,0-1.59-.28-2.12-.84s-.79-1.3-.79-2.23c0-1.01,.28-1.79,.83-2.36s1.29-.85,2.23-.85,1.59,.28,2.09,.83,.75,1.31,.75,2.29-.27,1.72-.81,2.3-1.27,.86-2.18,.86Zm.07-5.47c-.62,0-1.11,.21-1.46,.63s-.54,1-.54,1.74,.18,1.27,.54,1.68,.85,.62,1.46,.62,1.1-.2,1.43-.6,.5-.97,.5-1.72-.17-1.33-.5-1.73-.81-.61-1.43-.61Z"
                    />
                    <path className="cls-2" d="M94.15,165.12h-3.2v-.76h3.2v.76Z" />
                    <path
                      className="cls-2"
                      d="M96,168.09v-8.4h2.39c.73,0,1.3,.18,1.73,.53s.64,.82,.64,1.39c0,.48-.13,.89-.39,1.24s-.61,.6-1.07,.75v.02c.57,.07,1.02,.28,1.36,.64s.51,.83,.51,1.41c0,.72-.26,1.3-.77,1.75s-1.17,.67-1.95,.67h-2.45Zm.98-7.51v2.71h1.01c.54,0,.96-.13,1.27-.39s.46-.63,.46-1.1c0-.82-.54-1.22-1.61-1.22h-1.13Zm0,3.6v3.02h1.34c.58,0,1.03-.14,1.34-.41s.48-.65,.48-1.12c0-.99-.68-1.49-2.03-1.49h-1.13Z"
                    />
                    <path
                      className="cls-2"
                      d="M107.58,165.33h-4.24c.02,.67,.2,1.18,.54,1.55s.82,.54,1.42,.54c.68,0,1.3-.22,1.86-.67v.9c-.53,.38-1.22,.57-2.09,.57s-1.51-.27-2-.82-.73-1.31-.73-2.3c0-.93,.26-1.69,.79-2.28s1.19-.88,1.97-.88,1.39,.25,1.82,.76,.64,1.21,.64,2.12v.5Zm-.98-.81c0-.55-.14-.99-.4-1.29s-.63-.46-1.1-.46-.84,.16-1.15,.49-.51,.75-.59,1.27h3.24Z"
                    />
                    <path
                      className="cls-2"
                      d="M116.59,162.09l-1.8,6h-1l-1.24-4.29c-.05-.16-.08-.35-.09-.56h-.02c-.01,.14-.05,.32-.12,.54l-1.34,4.31h-.96l-1.82-6h1.01l1.24,4.51c.04,.14,.07,.32,.08,.54h.05c.01-.17,.05-.36,.11-.55l1.38-4.5h.88l1.24,4.52c.04,.14,.07,.32,.09,.54h.05c0-.15,.04-.33,.1-.54l1.22-4.52h.95Z"
                    />
                    <path
                      className="cls-2"
                      d="M122,168.09h-.96v-.94h-.02c-.42,.72-1.03,1.08-1.85,1.08-.6,0-1.07-.16-1.4-.47s-.51-.74-.51-1.26c0-1.12,.66-1.77,1.98-1.96l1.8-.25c0-1.02-.41-1.53-1.24-1.53-.72,0-1.38,.25-1.96,.74v-.98c.59-.38,1.27-.56,2.04-.56,1.41,0,2.12,.75,2.12,2.24v3.9Zm-3.37-7.14c-.16,0-.3-.06-.42-.17s-.17-.25-.17-.41c0-.16,.06-.3,.17-.41s.25-.16,.41-.16,.3,.06,.42,.17,.18,.25,.18,.41-.06,.3-.17,.41-.25,.17-.4,.17Zm2.41,4.1l-1.45,.2c-.45,.06-.78,.17-1.01,.33s-.34,.44-.34,.84c0,.29,.1,.53,.31,.72s.49,.28,.83,.28c.48,0,.87-.17,1.18-.5s.47-.76,.47-1.27v-.6Zm-.16-4.1c-.16,0-.29-.05-.4-.16s-.17-.25-.17-.42,.05-.3,.16-.41,.24-.16,.4-.16c.16,0,.3,.06,.42,.17s.17,.25,.17,.41-.05,.3-.16,.41-.25,.17-.41,.17Z"
                    />
                    <path
                      className="cls-2"
                      d="M124.77,168.09h-.96v-8.88h.96v8.88Z"
                    />
                    <path
                      className="cls-2"
                      d="M129.5,168.03c-.23,.12-.53,.19-.9,.19-1.05,0-1.58-.59-1.58-1.76v-3.55h-1.03v-.82h1.03v-1.46l.96-.31v1.78h1.51v.82h-1.51v3.38c0,.4,.07,.69,.21,.86s.36,.26,.68,.26c.24,0,.45-.07,.63-.2v.82Z"
                    />
                    <path
                      className="cls-2"
                      d="M131.27,160.57c-.17,0-.32-.06-.44-.18s-.18-.27-.18-.45,.06-.33,.18-.45,.27-.18,.44-.18,.33,.06,.45,.18,.18,.27,.18,.45-.06,.32-.18,.44-.27,.18-.45,.18Zm.47,7.52h-.96v-6h.96v6Z"
                    />
                    <path
                      className="cls-2"
                      d="M138.81,167.61c0,2.2-1.05,3.3-3.16,3.3-.74,0-1.39-.14-1.95-.42v-.96c.68,.38,1.32,.56,1.93,.56,1.48,0,2.21-.79,2.21-2.36v-.66h-.02c-.46,.77-1.14,1.15-2.06,1.15-.75,0-1.35-.27-1.8-.8s-.68-1.25-.68-2.15c0-1.02,.25-1.83,.74-2.43s1.16-.9,2.01-.9,1.41,.32,1.8,.97h.02v-.83h.96v5.52Zm-.96-2.23v-.88c0-.48-.16-.88-.48-1.22s-.72-.51-1.2-.51c-.59,0-1.06,.22-1.39,.65s-.5,1.04-.5,1.81c0,.67,.16,1.2,.48,1.6s.75,.6,1.28,.6,.98-.19,1.32-.57,.51-.87,.51-1.47Z"
                    />
                    <path
                      className="cls-2"
                      d="M145.6,168.09h-.96v-.95h-.02c-.4,.73-1.02,1.09-1.85,1.09-1.43,0-2.14-.85-2.14-2.55v-3.59h.96v3.43c0,1.27,.48,1.9,1.45,1.9,.47,0,.85-.17,1.16-.52s.45-.8,.45-1.36v-3.46h.96v6Z"
                    />
                    <path
                      className="cls-2"
                      d="M152.52,168.09h-.96v-3.42c0-1.27-.46-1.91-1.39-1.91-.48,0-.88,.18-1.19,.54s-.47,.82-.47,1.37v3.42h-.96v-6h.96v1h.02c.45-.76,1.11-1.14,1.97-1.14,.66,0,1.16,.21,1.51,.64s.52,1.04,.52,1.84v3.67Z"
                    />
                    <path
                      className="cls-2"
                      d="M159.46,167.61c0,2.2-1.05,3.3-3.16,3.3-.74,0-1.39-.14-1.95-.42v-.96c.68,.38,1.32,.56,1.93,.56,1.48,0,2.21-.79,2.21-2.36v-.66h-.02c-.46,.77-1.14,1.15-2.06,1.15-.75,0-1.35-.27-1.8-.8s-.68-1.25-.68-2.15c0-1.02,.25-1.83,.74-2.43s1.16-.9,2.01-.9,1.41,.32,1.8,.97h.02v-.83h.96v5.52Zm-.96-2.23v-.88c0-.48-.16-.88-.48-1.22s-.72-.51-1.2-.51c-.59,0-1.06,.22-1.39,.65s-.5,1.04-.5,1.81c0,.67,.16,1.2,.48,1.6s.75,.6,1.28,.6,.98-.19,1.32-.57,.51-.87,.51-1.47Z"
                    />
                  </g>
                  <circle className="cls-2" cx="15.24" cy="163.5" r="9.2" />
                  <path
                    className="cls-1"
                    d="M12.6,167.38v-1.02c.57,.37,1.16,.56,1.75,.56s1.07-.17,1.44-.5,.54-.78,.54-1.34-.19-.98-.56-1.29-.9-.46-1.59-.46c-.23,0-.67,.02-1.32,.07v-4.21h4.07v.87h-3.16v2.46c.26-.02,.5-.02,.71-.02,.9,0,1.59,.23,2.09,.68s.74,1.07,.74,1.85-.26,1.47-.79,1.96-1.25,.74-2.18,.74c-.78,0-1.36-.12-1.74-.35Z"
                  />
                </g>
              </g>
              </Link>
            </g>
          </svg> 
          <br></br>
          <br></br>
          <p> 
            Dieser beschreibt die einzelnen Phasen, in denen sich das
            Unternehmen systematisch und kontinuierlich mit den
            unternehmerischen Risikopotenzialen auseinandersetzt.
            <br></br><br></br>
            Klicken Sie auf einen der Prozessschritte, um mehr Informationen und Methoden dazu zu erhalten.</p>
            {/* <br></br>
            Mehr Informationen zu den einzelnen Prozess-Schritten sowie
            hilfreiche Templates sind in den folgenden Unterseiten enthalten:
          
          <br></br>
          <ProcessStep
            icon="Anker"
            title="Verankerung"
            intro="In der Organisation"
            description="adsfads fads f das fsdfads fadsfasdf asd fsdf asd dfasdfa"
            link="/Verankerung"
            number="1"
            style=""
          ></ProcessStep>
          <ProcessStep
            icon="lupe"
            style=""
            title="Identifikation"
            intro="von Risikoquellen"
            description="Lernen Sie Methoden zur Risikoidentifikation kennen"
            link="/Identifikation"
            number="2"
          ></ProcessStep>
          <ProcessStep
            icon="analyse"
            title="Analyse & Bewertung"
            style=""
            description="asdf"
            link="/Analyse"
            number="3"
          ></ProcessStep>
          
          <ProcessStep
            icon="move"
            title="Bewältigung"
            intro="Schadenmanagement"
            style=""
            description="asdf"
            link="/Bewältigung"
            number="4"
          ></ProcessStep>
        */}
        </div>
      </>
    );
  }
}

export default Prozess;
