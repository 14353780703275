import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../functions/ScrollToTop";
import de from "hyphenated-de";
import { hyphenated } from "hyphenated";

const text = hyphenated(
  `<p>
  Bei der Dokumentenanalyse werden Unternehmensunterlagen wie beispielsweise Verträge, Jahresabschlussberichte, Geldflussrechnungen, Dokumente der Kosten- und Leistungsrechnung und weitere betriebsspezifische Aufzeichnungen bezüglich risikorelevanter Informationen ausgewertet. Es können dabei sowohl schriftliche als auch elektronische Dokumente hinzugezogen werden. 
<br></br>
  </p>`,
  { language: de }
);

class Dokumentenanalyse extends Component {
  static name = "Dokumentenanalyse";
  render() {
    return (
      <>
        <ScrollToTop />
        <div id="main">
          <Link className="a-prozess" to="/Identifikation">
            &lt; Zurück zur Risikoidentifikation
          </Link>
          <br></br>
          <br></br>
          <h1>Dokumentenanalyse</h1>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
      </>
    );
  }
}

export default Dokumentenanalyse;
